import React from "react";

const DownloadButton =({fileLabel , fileLink})=>{

    return(
    <div className="container-fluid px-5">
    <div className="col-md-12">
      <div className="view-more-btnsec wiresol-btn">
        <a
          className="primary-link primary-link--dark"
          href= {fileLink}
          target="_blank"
          rel="noreferrer"
          download
        >
         {fileLabel}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="svg-icon"
          >
            <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4" />
            <polyline points="7 10 12 15 17 10" />
            <line x1="12" y1="15" x2="12" y2="3" />
          </svg>
        </a>
      </div>
    </div>
  </div>
  );
}

export default DownloadButton;