import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import ContactSec from "../ContactSec/ContactSec";
import LandingBanner from "../Banner/LandingBanner";
import SiteHeader from "../Header/SiteHeader";
import BarsRodsInfo from "../SubpageComp/BarsAndRods/BarsRodsInfo";
import BLineBarsRods from "../BuisnessLine/BLineBarsRods";
import { ApiBarsRods } from "../API/API";
import { useLocation } from "react-router-dom";
import MetaData from "../MetadataComp/MetaData";

const BarsRods = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [apiDataLoaded, setApiDataLoaded] = useState(false);
  const location = useLocation();
  //Api fetch for banner
  const [isLoadingBanner, setIsLoadingBanner] = useState(true);
  const [LandingBannerData, setLandingBannerData] = useState([
    {
      id: 0,
      bannerImage: null,
      bannerAlt: null,
      bannerTitle: null,
      bannerDescription: null,
    },
  ]);

  useEffect(() => {
    ApiBarsRods(apiUrl)
      .then((response) => {
        if (
          response.data.data.attributes.banner_section.length > 0
        ) {
          const item = response.data.data.attributes.banner_section[0];

          const newBannerData = {
            id: item.id,
            bannerImage: `${item.main_img.data.attributes.url}`,
            bannerAlt: item.img_alt_title,
            bannerTitle: item.navigator_text,
            bannerDescription: item.title,
          };

          setLandingBannerData(newBannerData);
          setIsLoadingBanner(false);
          setApiDataLoaded(true);
        } 
        // else {
        //   console.log("No data found.");
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[apiUrl]);
    

  //Api fetch for buisnessline
  const [isLoadingBLine, setIsLoadingBLine] = useState(true);
  const [bLineData, setBLineData] = useState([
    {
      id: 0,
      name: null,
      alt: null,
      image: null,
      route: "",
    },
  ]);

  useEffect(() => {
    ApiBarsRods(apiUrl)
      .then((response) => {
        if (
          response.data.data.attributes.businessline_block_item
        ) {
          const data_bl = response.data.data.attributes.businessline_block_item;
          data_bl.sort((a, b) => a.order_no - b.order_no);
          const newBLineData = data_bl.map((item) => {
            return {
              id: item.order_no,
              name: item.title_text,
              alt: item.img_alt_title,
              route: item.sec_id,
              image: `${item.bg_img.data.attributes.url}`,
            };
          });
          setIsLoadingBLine(false);
          setBLineData(newBLineData);
          setApiDataLoaded(true);
        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[apiUrl]);


  const sliderProps = {
    toShow: 3,
    toScroll: 1,
  };

  //Api fetch for info data
  const [isLoadingInfoData, setIsLoadingInfoData] = useState(true);
  const [infoData, setInfoData] = useState([
    {
      id: 0,
      infoId: null,
      infoTitle: null,
      infoPara: null,
      images: [],
      infoAlt: null
    },
  ]);

  useEffect(() => {
    ApiBarsRods(apiUrl)
      .then((response) => {
        if (
          response.data.data.attributes.businessline_block_item
        ) {
          const data_info =
            response.data.data.attributes.businessline_block_item;
          data_info.sort((a, b) => a.order_no - b.order_no);
          const newInfoData = data_info.map((item) => {
            const images = item.image_carousel.data.map(
              (imageData) => `${imageData.attributes.url}`
            );
            return {
              id: item.order_no,
              infoId: item.sec_id,
              infoTitle: item.title_text,
              infoPara: item.description,
              images: images,
              infoAlt: item.img_alt_title
            };
          });
          setIsLoadingInfoData(false);
          setInfoData(newInfoData);
          setApiDataLoaded(true);
        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[apiUrl]);
    

  //Api fetch for contact Data
  //Api fetch for contact heading1
  const [contactHeading1, setContactHeading1] = useState({ title: null });
  const [isLoadingContactHeading1, setIsLoadingContactHeading1] =
    useState(true);

  useEffect(() => {
    ApiBarsRods()
      .then((response) => {
        const { attributes } = response.data.data;
        if (attributes && attributes.Contact_us1) {
          setContactHeading1({ title: attributes.Contact_us1 });
        } 
        // else {
        //   console.log("Contact Heading 1 not found in API response.");
        // }
        setIsLoadingContactHeading1(false);
        setApiDataLoaded(true);
      })
      .catch((error) => {
        console.error("Error fetching contactHeading1 data: ", error);
      });
  }, []);

  //Api fetch for contact data 1
  const [isLoadingContactData1, setIsLoadingContactData1] = useState(true);
  const [contactData1, setContactData1] = useState([
    {
      item: {
        id: 0,
        names: null,
        countryCode: null,
        role: null,
        email: null,
        phone: null,
        address: null,
      },
    },
  ]);

  useEffect(() => {
    ApiBarsRods()
      .then((response) => {
        if (
          response.data.data.attributes.Contact_us1 &&
          response.data.data.attributes.contact_us1_fields
        ) {
          const data1 = response.data.data.attributes.contact_us1_fields;
          const newContactData1 = data1.map((item1) => {
            return {
              item: {
                id: item1.id,
                names: item1.name,
                countryCode: item1.country_code,
                role: item1.role,
                email: item1.email,
                phone: item1.contact_no,
                address: item1.address,
              },
            };
          });
          setIsLoadingContactData1(false);
          setContactData1(newContactData1);
          setApiDataLoaded(true);
        } 
        // else {
        //   console.log("Invalid response format:", response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[]);
  
  const [contactTitle, setContactTitle] = useState(null);
  useEffect(() => {
    ApiBarsRods(apiUrl)
      .then((response) => {
        if (response.data.data.attributes) {
          const item = response.data.data.attributes;
         setContactTitle(item.cont_sec_title)
         setApiDataLoaded(true);
        }
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [keywords, setKeyword] = useState(null);
  useEffect(() => {
    ApiBarsRods()
      .then((response) => {
        if (response.data.data.attributes.seo) {
          const item = response.data.data.attributes.seo;
          setTitle(item.page_title);
          setDescription(item.meta_description);
          setKeyword(item.meta_keywords);
        }
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  });

  useEffect(() => {
    if (
      !isLoadingBanner &&
      !isLoadingBLine &&
      !isLoadingContactData1 &&
      !isLoadingContactHeading1 &&
      !isLoadingInfoData 
    ) {
      setApiDataLoaded(true);
  
      const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      };
  
      setTimeout(() => {
        switch (location.hash) {
          case "#contact":
            scrollToSection("contact");
            break;
          case "#sp_bar":
            scrollToSection("sp_bar");
            break;
          case "#wire_coil":
            scrollToSection("wire_coil");
            break;
          case "#semis_sec":
            scrollToSection("semis_sec");
            break;
          default:
            break;
        }
      }, 1000); // Adjust the timeout duration as needed
    }
  }, [
    isLoadingBanner,
    isLoadingBLine,
    isLoadingContactData1,
    isLoadingContactHeading1,
    isLoadingInfoData,
    location.hash
  ]);
  useEffect(() => {
    if (!isLoadingBanner && !isLoadingBLine && !isLoadingInfoData && !isLoadingContactHeading1 && !isLoadingContactData1) {
      setApiDataLoaded(true);
    }
  }, [isLoadingBanner, isLoadingBLine, isLoadingInfoData, isLoadingContactData1, isLoadingContactHeading1]);

  if (
    isLoadingBanner ||
    isLoadingBLine ||
    isLoadingContactData1 ||
    isLoadingContactHeading1 || 
    isLoadingInfoData 
    ) {
    return (
      <div className="loading-animation">
        <span className="loader"></span>
      </div>
    );
  }
  return (
    <>
     <MetaData 
        title={title}
        description={description}
        keywords={keywords}
      />
      <SiteHeader apiDataLoaded={apiDataLoaded}/>
      <LandingBanner {...LandingBannerData} />
      <BLineBarsRods
        bLineData={bLineData}
        {...sliderProps}
        className="barrods-bline"
      />
      <section className="sections-common-sec barsnrods">
        {infoData.map((data) => (
          <BarsRodsInfo key={data.infoId} {...data} />
        ))}
      </section>
      <ContactSec
        contactHeading1={contactHeading1}
        contactData1={contactData1}
        contactTitle={contactTitle}
      />
      <Footer />
    </>
  );
};

export default BarsRods;
