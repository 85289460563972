import React from 'react';
import Map from './Map';
const MapSection = ({ mapHeading }) => {
  return (
    <section className="mapsec" id='mapsec'>
      <div className="srv-head">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <h3>{mapHeading}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="main-mapsec">
        <Map/>
      </div>
    </section>
  );
};

export default MapSection;
