import React from "react";
import { Accordion, Container, Row, Col } from "react-bootstrap";
import parse from "html-react-parser";

const WireRopesAccordian = ({ items, title }) => {
  return (
    <section className="st-gallsec">
      <Container fluid className="px-5">
        <h3 className="text-center">{title}</h3>
        <div className="st-accodian-sec">
          <div className="col-md-12">
            <Accordion defaultActiveKey="0">
              {items.map((item, index) => (
                <Accordion.Item key={index} eventKey={index.toString()}>
                  <Accordion.Header>{item.header}</Accordion.Header>
                  <Accordion.Body>
                    <div className="info-multi">
                      <Row className="align-items-center">
                        <Col md={12}>
                          <div className="main-info">
                            <div className="info-text">
                              {item.content && parse(item.content)}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default WireRopesAccordian;
