import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/custom-style.css";
import "../../assets/css/variable.css";
import parse from 'html-react-parser';


const Banner = ({ bannerData }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);
  };

  const updateIndicators = (index) => {
    const indicators = document.querySelectorAll('.carousel-indicators button span');
    indicators.forEach((indicator, i) => {
      if (i === index) {
        indicator.style.transition = 'width 5.5s ease-out';
        indicator.style.width = '100%';
      } else {
        indicator.style.transition = 'width 0s';
        indicator.style.width = '0%';
      }
    });
  };

  useEffect(() => {
    updateIndicators(activeIndex);
  }, [activeIndex]);

  return (
    <section className="banner-section">
      <div
        id="carouselExampleIndicators"
        className="carousel slide carousel-fade"
        data-bs-ride="carousel"
        data-bs-pause="true"
      >
        <Carousel
          activeIndex={activeIndex}
          onSelect={handleSelect}
          fade={true}
          interval={5000}
          indicators={false}
          controls={false}
          pause={false}
        >
          {bannerData.map((slide) => (
            <Carousel.Item key={slide.id}>
              <img className="main-img d-block w-100" src={slide.image} alt={slide.alt} />
              <div className="banner-contentsec banner-single container-fluid px-5">
                <div className="row">
                  <div className="col-md-8">
                  {parse(slide.caption)}
                  </div>
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
        {bannerData.length > 1 && (
        <div className="carousel-indicators">
          {bannerData.map((slide, index) => (
            <button
              key={slide.id}
              onClick={() => handleSelect(index)}
              className={index === activeIndex ? 'active' : '100'}
              aria-label={`Slide ${index + 1}`}
            >
              {slide.indicatorText && (
                <>
                  <span></span>
                  <div className="cartext">
                    <p className="indicator-text">{`0${index + 1} \u00A0\u00A0 ${slide.indicatorText}`}</p>
                  </div>
                </>
              )}
            </button>
          ))}
        </div>
        )}
      </div>
    </section>
  );
};

export default Banner;
