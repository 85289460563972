import React, { useEffect,useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/custom-style.css";
import "../../assets/css/variable.css";
import Slider from "react-slick";
import "../../assets/css/slick-carousel/slick/slick.css";
import "../../assets/css/slick-carousel/slick/slick-theme.css";

  const BLineBarsRods = ({ bLineData, toShow, toScroll , className}) => {
     
      const [activeDotIndex, setActiveDotIndex] = useState(0);
      const [shouldShowDots, setShouldShowDots] = useState(false);
      const sliderRef = useRef(null);
    
      useEffect(() => {
        const slider = sliderRef.current;
        if (slider && slider.innerSlider) {
          const { slideCount } = slider.innerSlider.state;
          setActiveDotIndex(slider.innerSlider.state.currentSlide);
          setShouldShowDots(slideCount > toShow);
          const initialActiveIndex = 0;
          updateCircle(initialActiveIndex);
        }
      }, [toShow, shouldShowDots]);
      
      useEffect(() => {
        updateCircle(activeDotIndex);
      }, [activeDotIndex]);

      const handleDotClick = (index) => {
        setActiveDotIndex(index);
        const slideIndex = index * toScroll; // Use toScroll instead of toShow
        sliderRef.current.slickGoTo(slideIndex);
        // updateCircle(index);
      };
    
      const updateCircle = (activeIndex) => {
        const dots = document.querySelectorAll(".custom-slick-nav .slick-dots li button");
        dots.forEach((dot, index) => {
        const circle = dot.querySelector(".progress-svg");
        if (circle) {
            dot.removeChild(circle);
          }
          if (index === activeIndex) {
            const newCircle = document.createElementNS("http://www.w3.org/2000/svg", "svg");
            newCircle.setAttribute("class", "progress-svg");
            newCircle.innerHTML = '<circle class="circle-bg" r="9" cx="10" cy="10"></circle><circle class="circle-go" r="9" cx="10" cy="10"></circle>';
            dot.appendChild(newCircle);
          }
        });      
      };
    
      const renderCustomDots = () => {
        const totalSlides = bLineData.length;
        let dotsCount = totalSlides;
      
        // Check screen size for mobile version (<= 480px)
        if (window.innerWidth <= 767 ) {
          dotsCount = totalSlides;
          toShow = 1; // Assuming setToShow is a function to update the state of toShow
        } else if(window.innerWidth >= 768 && window.innerWidth<=992){
          toShow=2;
          dotsCount = totalSlides - toShow + 1; 
        }
        else {
          // For desktop version (> 480px), calculate dots count based on toShow
          dotsCount = totalSlides - toShow + 1; // Formula: dots = (totalSlides - toShow) + 1
        }

        if (settings.infinite) {
          dotsCount = totalSlides; // Reset dots count to totalSlides if infinite is true
        }

        const dots = [];
        for (let i = 0; i < dotsCount; i++) {
          dots.push(
            <li
              key={i}
              className={`slick-dot ${activeDotIndex === i ? "slick-active" : ""}`}
              onClick={() => handleDotClick(i)}
            >
              <button></button>
            </li>
          );
        }
        return dots;
      };
        const [shouldDisable, setShouldDisable] = useState(false);
        useEffect(() => {
          const slider = sliderRef.current;
          if (slider && slider.innerSlider) {
            const { slideCount } = slider.innerSlider.state;
            setShouldDisable(slideCount <= toShow);
          }
        }, [toShow]);

        const handlePrevClick = () => {
          if (sliderRef.current && activeDotIndex > 0) {
            sliderRef.current.slickPrev();
            const newIndex = activeDotIndex - 1;
            setActiveDotIndex(newIndex);
            //  updateCircle(newIndex);
          }
        };
        
        const handleNextClick = () => {
          const dotsCount = renderCustomDots().length;
        
          if (sliderRef.current && activeDotIndex < dotsCount - 1) {
            sliderRef.current.slickNext();
            const newIndex = activeDotIndex + 1;
            setActiveDotIndex(newIndex);
            // updateCircle(newIndex);
          }
        };
       
        const settings = {
          infinite: false,
          draggable: false,
          autoplay: true,
          autoplaySpeed: 4700,
          speed: 500,
          arrows: false,
          dots:false,
          pauseOnHover:false,
          slidesToShow: toShow,
          slidesToScroll: toScroll,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: toShow,
                slidesToScroll: toScroll,
                arrows: false,
                 autoplay: true,
                 infinite: true,
                draggable: false,
              },
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
                 autoplay: true,
                 infinite: true,
                draggable: false,
              },
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                 autoplay: true,
                 infinite: true,
                draggable: false,
                dots:false
              },
            },
            
          ],
          beforeChange: (current, next) => setActiveDotIndex(next),
        };

        const handleDropdownItemClick = (sectionId) => {
          setTimeout(() => {
            const section = document.getElementById(sectionId);
            if (section) {
              const offsetTop = section.getBoundingClientRect().top;
              const navbarHeight = document.querySelector(".navbar").offsetHeight;
              const scrollPosition = window.scrollY + offsetTop - navbarHeight;
              window.scrollTo({ top: scrollPosition, behavior: "smooth" });
            }
          }, 100); 
        };
      
        return (
          <section className={`full-slsec ${className}`}>
            <Slider {...settings} className="srv-slider" ref={sliderRef}>
              {bLineData.map((item) => (
                <div key={item.id} className="item">
                  <div className="single-srv-grid">
                    <div className="mn-im">
                      <img className="srv-main" src={item.image} alt={item.alt} />
                    </div>
                    <div className="ovrly">
                      <div className="ovrly-content">
                        <p>{item.name}</p>
                        <NavLink className="site-btn1" onClick={() => handleDropdownItemClick(item.route)}>
                          View More
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            {!shouldDisable && (
              <div className="custom-slick-nav slnav1">
                  <button className="slick-prev" onClick={handlePrevClick}>Previous</button>
                  <ul className="slick-dots">
                    {renderCustomDots()}
                  </ul>  
                  <button className="slick-next" onClick={handleNextClick}>Next</button>
              </div>
            )}
          </section>
        );
      };

      
        
      

export default BLineBarsRods;
