import React from "react";
import parse from 'html-react-parser';

const SubpageGrid=({para1,para2})=>{
    return(
        <section className="gridsec">
      <div className="grd grd1">
        <div className="subtext">{para1 && parse(para1)}</div>
      </div>
      <div className="grd grd2">
        <div className="subtext">{para2 && parse(para2)}</div>
      </div>
    </section>
    );
}

export default SubpageGrid;