import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Info from "../Info/Info";
import ContactSec from "../ContactSec/ContactSec";
import LandingBanner from "../Banner/LandingBanner";
import SiteHeader from "../Header/SiteHeader";
import BLineFlats from "../BuisnessLine/BLineFlats";
import { ApiFlats } from "../API/API";
import { useLocation } from "react-router-dom";
import MetaData from "../MetadataComp/MetaData";
const Flats = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [apiDataLoaded, setApiDataLoaded] = useState(false);
  const location = useLocation();
  const sliderProps = {
    toShow: 4,
    toScroll: 1,
  };

  //Api fetch for banner
  const [isLoadingBanner, setIsLoadingBanner] = useState(true);
  const [LandingBannerData, setLandingBannerData] = useState([
    {
      id: 0,
      bannerImage: null,
      bannerAlt: null,
      bannerTitle: null,
      bannerDescription: null,
    },
  ]);

  useEffect(() => {
    ApiFlats(apiUrl)
      .then((response) => {
        if (response.data.data.attributes.banner_section.length > 0) {
          const item = response.data.data.attributes.banner_section[0];

          const newBannerData = {
            id: item.id,
            bannerImage: `${item.main_img.data.attributes.url}`,
            bannerAlt: item.img_alt_title,
            bannerTitle: item.navigator_text,
            bannerDescription: item.title,
          };

          setLandingBannerData(newBannerData);
          setIsLoadingBanner(false);
        }
        // else {
        //   console.log("No data found.");
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  //Api fetch for buisnessline
  const [isLoadingBLine, setIsLoadingBLine] = useState(true);
  const [bLineData, setBLineData] = useState([
    {
      id: 0,
      name: null,
      image: null,
      alt: null,
      route: "",
    },
  ]);

  useEffect(() => {
    ApiFlats(apiUrl)
      .then((response) => {
        if (response.data.data.attributes.businessline_block_item) {
          const data_bl = response.data.data.attributes.businessline_block_item;
          data_bl.sort((a, b) => a.order_no - b.order_no);
          const newBLineData = data_bl.map((item) => {
            return {
              id: item.order_no,
              name: item.title_text,
              route: item.url,
              alt: item.img_alt_title,
              image: `${item.bg_img.data.attributes.url}`,
            };
          });
          setIsLoadingBLine(false);
          setBLineData(newBLineData);
        }
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  //Api fetch for contact Data
  //Api fetch for contact heading1
  const [contactHeading1, setContactHeading1] = useState({ title: null });
  const [isLoadingContactHeading1, setIsLoadingContactHeading1] =
    useState(true);

  useEffect(() => {
    ApiFlats()
      .then((response) => {
        const { attributes } = response.data.data;
        if (attributes && attributes.Contact_us1) {
          setContactHeading1({ title: attributes.Contact_us1 });
        }
        // else {
        //   console.log("Contact Heading 1 not found in API response.");
        // }
        setIsLoadingContactHeading1(false);
      })
      .catch((error) => {
        console.error("Error fetching contactHeading1 data: ", error);
      });
  }, []);

  //Api fetch for contact data 1
  const [isLoadingContactData1, setIsLoadingContactData1] = useState(true);
  const [contactData1, setContactData1] = useState([
    {
      item: {
        id: 0,
        names: null,
        countryCode: null,
        role: null,
        email: null,
        phone: null,
        address: null,
      },
    },
  ]);

  useEffect(() => {
    ApiFlats()
      .then((response) => {
        if (
          response.data.data.attributes.Contact_us1 &&
          response.data.data.attributes.contact_us1_fields
        ) {
          const data1 = response.data.data.attributes.contact_us1_fields;
          const newContactData1 = data1.map((item1) => {
            return {
              item: {
                id: item1.id,
                names: item1.name,
                countryCode: item1.country_code,
                role: item1.role,
                email: item1.email,
                phone: item1.contact_no,
                address: item1.address,
              },
            };
          });
          setIsLoadingContactData1(false);
          setContactData1(newContactData1);
        }
        // else {
        //   console.log("Invalid response format:", response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  //Api fetch for Contact heading 2
  const [contactHeading2, setContactHeading2] = useState({ title: null });
  const [isLoadingContactHeading2, setIsLoadingContactHeading2] =
    useState(true);

  useEffect(() => {
    ApiFlats()
      .then((response) => {
        const { attributes } = response.data.data;
        if (attributes && attributes.Contact_us2) {
          setContactHeading2({ title: attributes.Contact_us2 });
        }
        // else {
        //   console.log("Contact Heading 2 not found in API response.");
        // }
        setIsLoadingContactHeading2(false);
      })
      .catch((error) => {
        console.error("Error fetching contactHeading2 data: ", error);
      });
  }, []);

  //Api fetch for contact data2
  const [isLoadingContactData2, setIsLoadingContactData2] = useState(true);
  const [contactData2, setContactData2] = useState([
    {
      item: {
        id: 0,
        names: null,
        countryCode: null,
        role: null,
        email: null,
        phone: null,
        address: null,
      },
    },
  ]);

  useEffect(() => {
    ApiFlats()
      .then((response) => {
        if (
          response.data.data.attributes.Contact_us2 &&
          response.data.data.attributes.contact_us2_fields
        ) {
          const data2 = response.data.data.attributes.contact_us2_fields;
          const newContactData2 = data2.map((item2) => {
            return {
              item: {
                id: item2.id,
                names: item2.name,
                countryCode: item2.country_code,
                role: item2.role,
                email: item2.email,
                phone: item2.contact_no,
                address: item2.address,
              },
            };
          });
          setIsLoadingContactData2(false);
          setContactData2(newContactData2);
        }
        // else {
        //   console.log("Invalid response format:", response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  //Api fetch for sustainability(infoData)

  const [isLoadingInfoData, setIsLoadingInfoData] = useState(true);
  const [infoData, setinfoData] = useState([
    {
      id: 0,
      infoTitle: null,
      infoImage: null,
      infoPara: null,
      infoAlt: null,
      isButtonEnabled: true,
      buttonRoute: null,
    },
  ]);

  useEffect(() => {
    ApiFlats(apiUrl)
      .then((response) => {
        if (response.data.data.attributes.sustainaibility) {
          const item = response.data.data.attributes.sustainaibility;

          const newInfoData = {
            id: item.id,
            infoTitle: item.sustainaibility_heading,
            infoImage: `${item.side_img.data.attributes.url}`,
            isButtonEnabled: true,
            infoAlt: item.side_img_alt_txt,
            buttonRoute: "/flats/sustainability",
          };
          setinfoData(newInfoData);
          setIsLoadingInfoData(false);
        }
        //  else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  const [contactTitle, setContactTitle] = useState(null);
  useEffect(() => {
    ApiFlats(apiUrl)
      .then((response) => {
        if (response.data.data.attributes) {
          const item = response.data.data.attributes;
          setContactTitle(item.cont_sec_title);
        }
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [keywords, setKeyword] = useState(null);
  useEffect(() => {
    ApiFlats()
      .then((response) => {
        if (response.data.data.attributes.seo) {
          const item = response.data.data.attributes.seo;
          setTitle(item.page_title);
          setDescription(item.meta_description);
          setKeyword(item.meta_keywords);
        }
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  });
  useEffect(() => {
    if (
      !isLoadingBanner &&
      !isLoadingBLine &&
      !isLoadingInfoData &&
      !isLoadingContactHeading1 &&
      !isLoadingContactData1 &&
      !isLoadingContactData2 &&
      !isLoadingContactHeading2
    ) {
      setApiDataLoaded(true);
        if (location.hash === "#contact") {
          setTimeout(() => {
            const contactSection = document.getElementById("contact");
            if (contactSection) {
              contactSection.scrollIntoView({ behavior: "smooth" });
            }
          }, 1000); // Adjust the timeout duration as needed
        }
    }
  }, [
    isLoadingBanner,
    isLoadingBLine,
    isLoadingInfoData,
    isLoadingContactData1,
    isLoadingContactHeading1,
    isLoadingContactData2,
    isLoadingContactHeading2,
    location.hash,
  ]);

  useEffect(() => {
    if (
      !isLoadingBanner &&
      !isLoadingBLine &&
      !isLoadingInfoData &&
      !isLoadingContactHeading1 &&
      !isLoadingContactData1 &&
      !isLoadingContactData2 &&
      !isLoadingContactHeading2
    ) {
      setApiDataLoaded(true);
    }
  }, [
    isLoadingBanner,
    isLoadingBLine,
    isLoadingInfoData,
    isLoadingContactData1,
    isLoadingContactHeading1,
    isLoadingContactData2,
    isLoadingContactHeading2,
  ]);

  if (
    isLoadingBanner ||
    isLoadingBLine ||
    isLoadingInfoData ||
    isLoadingContactData1 ||
    isLoadingContactData2 ||
    isLoadingContactHeading1 ||
    isLoadingContactHeading2
  ) {
    return (
      <div className="loading-animation">
        <span className="loader"></span>
      </div>
    );
  }

  return (
    <>
     <MetaData 
        title={title}
        description={description}
        keywords={keywords}
      />
      <SiteHeader apiDataLoaded={apiDataLoaded} />
      <LandingBanner {...LandingBannerData} />
      <BLineFlats bLineData={bLineData} {...sliderProps} />
      <section className="sections-common-sec">
        <Info {...infoData} />
      </section>
      <ContactSec
        contactHeading1={contactHeading1}
        contactData1={contactData1}
        contactHeading2={contactHeading2}
        contactData2={contactData2}
        contactTitle={contactTitle}
      />
      <Footer />
    </>
  );
};

export default Flats;
