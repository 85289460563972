import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/custom-style.css";
import "../../assets/css/variable.css";
import { Link } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import parse from "html-react-parser";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Newsletter = ({ title, para, buttonLabel }) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    org: "",
    interest: [], // Initialize interests as an empty array
  });
  const [showModal, setShowModal] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    let timer;
    if (alertVisible) {
      timer = setTimeout(() => {
        setAlertVisible(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [alertVisible]);

  const handleClose = () => {
    setShowModal(false);
    setAlertVisible(false);
    setFormData({
      name: "",
      email: "",
      phone: "",
      org: "",
      interests: [],
    });
    setErrors({});
  };

  const handleShow = () => {
    setShowModal(true);
    setAlertVisible(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      phone: "",
    }));
  };

  const handleInterestsChange = (selectedList) => {
    setFormData((prevData) => ({
      ...prevData,
      interests: selectedList.map((item) => ({
        id: item.id,
        interested_in: item.interested_in,
      })),
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      interests: "",
    }));

    // setSelectedValues([]);
  };

  const validate = () => {
    let errors = {};
    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }
    if (!formData.org) errors.org = "Organization Name is required";
    if (!formData || !formData.interests || formData.interests.length === 0) {
      errors.interests = "At least one interest is required";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      setErrors({});
    }

    const payload = {
      data: {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        org: formData.org,
        interests: formData.interests.map((interest) => ({
          interested_in: interest.interested_in,
        })),
      },
    };

    // console.log("Payload before sending:", payload);

    try {
      // setLoading(true);
      const response = await fetch(`${apiUrl}/api/subscribers?populate=*`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const responseData = await response.json();
      if (response.ok) {
        handleClose();
        setAlertVisible(true);
        toast.success("Successfully subscribed!");
        setFormData({
          name: "",
          email: "",
          phone: "",
          org: "",
          interests: [],
        });
      } else {
        console.error("Form submission failed:", responseData.error.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error.message);
    } finally {
      // setLoading(false);
    }
  };

  // const notify = () => toast("Successfully Subscribed !")

  return (
    <>
      <section className="hm-newsletter">
        <div className="container-fluid px-5">
          <div className="nws-wrapper">
            <div className="main-newslettergrid">
              <div className="row">
                <div className="col-md-12">
                  <div className="news-form">
                    <div className="nws-frm-text">
                      {title && parse(title)}
                      {para && parse(para)}
                    </div>
                    <div className="main-newsform">
                      <Link
                        className="primary-link primary-link--dark"
                        onClick={handleShow}
                      >
                        {buttonLabel}
                        <svg
                          viewBox="0 0 33 14"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-labelledby="Long Arrow"
                          role="presentation"
                          className="svg-icon"
                        >
                          <title id="Long Arrow" lang="en">
                            Long Arrow icon
                          </title>
                          <polygon
                            points="25.1604773 0 32.6352213 6.85610681 25.1993812 13.7359725 24.3690601 12.8391022 30.1441884 7.49516092 0.0024154589 7.43569437 0 6.21588761 30.2001884 6.27516092 24.3378796 0.897439017"
                            stroke="none"
                            strokeWidth="1"
                            fill="currentColor"
                            fillRule="evenodd"
                          ></polygon>
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={showModal}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>Subscribe to our email alerts</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} hasValidation>
            <Form.Group className="form-group">
              <Form.Control
                type="text"
                placeholder="Name *"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                isInvalid={!!errors.name}
              />
              <div className="invalid-feedback" type="invalid"></div>
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Control
                type="email"
                placeholder="Email *"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                isInvalid={!!errors.email}
              />
              <div className="invalid-feedback" type="invalid"></div>
            </Form.Group>

            <Form.Group className="form-group">
              <PhoneInput
                className="form-control"
                defaultCountry="US"
                placeholder="Phone Number"
                value={formData.phone}
                onChange={handlePhoneChange}
                inputprops={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Control
                type="text"
                placeholder="Organization Name *"
                name="org"
                value={formData.org}
                onChange={handleChange}
                required
                isInvalid={!!errors.org}
              />
              <div className="invalid-feedback" type="invalid"></div>
            </Form.Group>
            <Form.Group className="form-group">
              <Multiselect
                className={`form-control ${
                  errors.interests ? "is-invalid" : ""
                }`}
                options={[
                  { id: 1, interested_in: "Structural Shapes" },
                  { id: 2, interested_in: "Bars and Rods" },
                  { id: 3, interested_in: "Flat Products" },
                  { id: 4, interested_in: "Wire Solutions" },
                  { id: 5, interested_in: "Logistics" },
                ]}
                displayValue="interested_in"
                name="interests"
                placeholder={
                  !formData.interests || formData.interests.length === 0
                    ? "Select Interests *"
                    : ""
                }
                selectedValues={formData.interests}
                onSelect={handleInterestsChange}
                onRemove={handleInterestsChange}
                avoidHighlightFirstOption={true}
              />

              <div className="invalid-feedback" type="invalid"></div>
            </Form.Group>

            <Modal.Footer>
              <button
                className="btn primary-link primary-link--dark"
                onClick={handleSubmit}
              >
                Submit
                <svg
                  viewBox="0 0 33 14"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-labelledby="Long Arrow"
                  role="presentation"
                  className="svg-icon"
                >
                  <title id="Long Arrow" lang="en">
                    Long Arrow icon
                  </title>
                  <polygon
                    points="25.1604773 0 32.6352213 6.85610681 25.1993812 13.7359725 24.3690601 12.8391022 30.1441884 7.49516092 0.0024154589 7.43569437 0 6.21588761 30.2001884 6.27516092 24.3378796 0.897439017"
                    stroke="none"
                    strokeWidth="1"
                    fill="currentColor"
                    fillRule="evenodd"
                  ></polygon>
                </svg> 
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      {/* {alertVisible && (
        <div className="alert alert-success fixed-alert" role="alert">
          Successfully subscribed!
        </div>
      )} */}
      <ToastContainer />
    </>
  );
};

export default Newsletter;
