import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/custom-style.css";
import "../../../assets/css/variable.css";
import "../../../assets/css/slick-carousel/slick/slick.css";
import "../../../assets/css/slick-carousel/slick/slick-theme.css";
import parse from 'html-react-parser';


const BarsRodsInfo = ({ sId, infoTitle, infoPara, images, infoId, infoAlt }) => {
  const [activeDotIndex, setActiveDotIndex] = useState(0);
  const sliderRef = useRef(null);
  const dotsRef = useRef(null); // Ref for dots container

  const settings = {
    infinite: true,
    draggable: false,
    autoplay: true,
    autoplaySpeed: 4700,
    speed: 500,
    arrows: false,
    pauseOnHover:false,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setActiveDotIndex(next),
  };

  useEffect(() => {
    const slider = sliderRef.current;
    const dots = dotsRef.current;
    if (slider && slider.innerSlider && dots) {
      //const { slideCount } = slider.innerSlider.state;
      setActiveDotIndex(slider.innerSlider.state.currentSlide);
      updateCircle(activeDotIndex, dots); // Update circle initially
    }
  }, [activeDotIndex, images.length]);

  const handleDotClick = (index) => {
    setActiveDotIndex(index);
    const slideIndex = index * settings.slidesToScroll; // Use slidesToScroll instead of slidesToShow
    sliderRef.current.slickGoTo(slideIndex);
    updateCircle(index, dotsRef.current);
  };

  const updateCircle = (activeIndex, dotsContainer) => {
    const dots = dotsContainer.querySelectorAll('.slick-dot button'); // Query within dots container
    dots.forEach((dot, index) => {
      const circle = dot.querySelector('.progress-svg');
      if (circle) {
        dot.removeChild(circle);
      }
      if (index === activeIndex) {
        const newCircle = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        newCircle.setAttribute('class', 'progress-svg');
        newCircle.innerHTML = '<circle class="circle-bg" r="9" cx="10" cy="10"></circle><circle class="circle-go" r="9" cx="10" cy="10"></circle>';
        dot.appendChild(newCircle);
      }
    });
  };

  const renderCustomDots = () => {
    const dots = [];
    for (let i = 0; i < images.length; i++) {
      dots.push(
        <li
          key={i}
          className={`slick-dot ${activeDotIndex === i ? 'slick-active' : ''}`}
          onClick={() => handleDotClick(i)}
        ><button></button></li>
      );
    }
    return dots;
  };

  const [shouldDisable, setShouldDisable] = useState(false);
  useEffect(() => {
    setShouldDisable(images.length <= 1);
  }, [images.length]);

  const handlePrevClick = () => {
    if (sliderRef.current && activeDotIndex > 0) {
      sliderRef.current.slickPrev();
      const newIndex = activeDotIndex - 1;
      setActiveDotIndex(newIndex);
      updateCircle(newIndex, dotsRef.current);
    }
  };

  const handleNextClick = () => {
    if (sliderRef.current && activeDotIndex < images.length - 1) {
      sliderRef.current.slickNext();
      const newIndex = activeDotIndex + 1;
      setActiveDotIndex(newIndex);
      updateCircle(newIndex, dotsRef.current);
    }
  };

  return (
    <div className="info" id={infoId ? infoId.toString() : ''}>
    <div className="container-fluid px-5">
      <div className="row align-items-center">
        <div className="col-md-6">
          <div className="main-info">
            <div className="info-text subtext">
              <h3>{infoTitle}</h3>
              <div className="subtext">{infoPara && parse(infoPara)}</div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="main-img-plot">
          {images && images.length === 1 ? (
                <div>
                  <img src={images[0]} alt={infoAlt} />
                </div>
              ) : (
                <Slider {...settings} ref={sliderRef}>
                  {images.map((image, index) => (
                    <div key={index}>
                      <img src={image} alt={infoAlt} />
                    </div>
                  ))}
                </Slider>
              )}
              {!shouldDisable && (
                <div className="custom-slick-nav slnav1">
                  <button className="slick-prev" onClick={handlePrevClick}>Previous</button>
                  <ul className="slick-dots" ref={dotsRef}>
                    {renderCustomDots()}
                  </ul>
                  <button className="slick-next" onClick={handleNextClick}>Next</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarsRodsInfo;
