import React, { useEffect, useState } from "react";
import SiteHeader from "../../Header/SiteHeader";
import SubpageBanner from "../../Banner/SubpageBanner";
import Footer from "../../Footer/Footer";
import BlogComp from "../../LatestNews/BlogComp";
import { ApiLatestNews } from "../../API/API";
import MetaData from "../../MetadataComp/MetaData";


const BlogPage=()=>{
  const [title] = useState("Latest News - ArcelorMittal International North America");
  const [description] = useState("Get the list of latest news published on ArcelorMittal International North America");
  const [keywords] = useState("News List, Latest News of ArcelorMittal International North America");
  const apiUrl = process.env.REACT_APP_API_URL;

    const bannerData={
        title: 'Our News',
        pageLink: '',
        linkTitle: 'Media',
      }

      const [isLoadingBlogData, setIsLoadingBlogData] = useState(true);

      const [datanewstest, Setdatanewstest] = useState([]);
      useEffect(() => {
        ApiLatestNews()
          .then((LNBresponse) => {
            if ( LNBresponse.data.data) {
              
              const LNBdata = LNBresponse.data.data;
              LNBdata.sort((a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt));
              Setdatanewstest(LNBdata);

              setIsLoadingBlogData(false);
            } 

          })
          .catch((error) => {
            console.error("Error fetching BlogPage ApiLatestNewsdata: ", error.message);
          });
      }, [apiUrl]);
      

      if (isLoadingBlogData) {
        return (
          <div className="loading-animation">
           <span className="loader"></span>
          </div>
        );
      }


    return(
        <>
        <MetaData 
          title={title}
          description={description}
          keywords={keywords}
        />
        <SiteHeader />
        <SubpageBanner {...bannerData}/>
        <BlogComp blogData={datanewstest} />
        <Footer />
        </>
    )
}

export default BlogPage;