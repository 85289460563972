import React from "react";
import HomePage from "./Components/MainComponents/HomePage";
import StructuralShapes from "./Components/MainComponents/StructuralShapes";
import BarsRods from "./Components/MainComponents/BarsRods";
import Flats from "./Components/MainComponents/Flats";
import Logistics from "./Components/MainComponents/Logistics";
import WireSolutions from "./Components/MainComponents/WireSolutions";
import ProductSizeRange from "./Components/SubpageComp/StructuralShapes/ProductSizeRange";
import { Route, Routes } from "react-router-dom";
import Sustainability from "./Components/SubpageComp/StructuralShapes/Sustainability";
import Agriculture from "./Components/SubpageComp/Flats/Agriculture";
import Automotive from "./Components/SubpageComp/Flats/Automotive";
import Appliances from "./Components/SubpageComp/Flats/Appliances";
import Building from "./Components/SubpageComp/Flats/Building";
import FlatSustainability from "./Components/SubpageComp/Flats/FlatSustainability";
import ProjectGallery from "./Components/SubpageComp/StructuralShapes/ProjectGallery";
import A913HighSteel from "./Components/SubpageComp/StructuralShapes/A913HighSteel";
import CAGMachinery from "./Components/SubpageComp/Flats/CAGMachinery";
import Packaging from "./Components/SubpageComp/Flats/Packaging";
import Energy from "./Components/SubpageComp/Flats/Energy";
import Transportation from "./Components/SubpageComp/Flats/Transportation";
import SteelFibres from "./Components/SubpageComp/WireSolutions/SteelFibres";
import FencingVineyardWire from "./Components/SubpageComp/WireSolutions/FencingVineyardWire";
import WireRopes from "./Components/SubpageComp/WireSolutions/WireRopes";
import BlogPage from "./Components/SubpageComp/BlogPage/BlogPage";
import BlogDetail from "./Components/SubpageComp/BlogPage/BlogDetail";
import Search from "./Components/Search/Search";
import NotFoundPage from "./Components/ErrorPage/NotFoundPage";


const App = () => {
  return (
  <Routes>
    <Route exact path="/" element={<HomePage name="HomePage"/>} />
    <Route path="/structural-shapes" element = {<StructuralShapes name="StructuralShapes"/>} />
    <Route path="/bars-and-rods" element = {<BarsRods  name="BarsRods"/>} />
    <Route path="/flats" element = {<Flats  name="Flats"/>} />
    <Route path="/logistics" element = {<Logistics  name="Logistics"/>} />
    <Route path="/wire-solutions" element = {<WireSolutions  name="WireSolutions"/>} />
    <Route path="/structural-shapes/product-size-range" element={<ProductSizeRange  name="ProductSizeRange"/>} />
    <Route path="/structural-shapes/sustainability" element={<Sustainability  name="Sustainability"/>} />
    <Route path="/structural-shapes/project-gallery" element={<ProjectGallery  name="ProjectGallery"/>} />
    <Route path="/structural-shapes/A913_HighStrengthSteel" element={<A913HighSteel  name="A913HighSteel"/>} />
    <Route path="/flats/agriculture" element={<Agriculture  name="Agriculture"/>} />
    <Route path="/flats/automotive" element={<Automotive  name="Automotive"/>} />
    <Route path="/flats/appliances" element= {<Appliances  name="Appliances"/>} /> 
    <Route path="/flats/building" element={<Building name="Building"/>} />
    <Route path="/flats/sustainability" element={<FlatSustainability name="FlatSustainability"/>} />
    <Route path="/flats/construction" element={<CAGMachinery  name="CAGMachinery"/>} />
    <Route path="/flats/packaging" element={<Packaging  name="Packaging"/>} />
    <Route path="/flats/energy" element={<Energy  name="Energy"/>} />
    <Route path="/flats/transportation" element={<Transportation  name="Transportation"/>} />
    <Route path="/wire-solutions/steel-fibres" element = {<SteelFibres  name="SteelFibres"/>} />
    <Route path="/wire-solutions/fencing-vineyard-wire" element = {<FencingVineyardWire  name="FencingVineyardWire"/>} />
    <Route path="/wire-solutions/wire-ropes" element = {<WireRopes  name="WireRopes"/>} />
    <Route path="/latest-news" element = {<BlogPage name="BlogPage"/>} />
    <Route path="/latest-news/details/:id" element = {<BlogDetail name="BlogDetail"/>} />
    <Route path="/search" element = {<Search name="Search"/>} />
    <Route path="*" element = {<NotFoundPage name="NotFoundPage"/>} />
  </Routes>  
    )
  };
  

export default App;
