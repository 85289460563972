import React from "react";
import parse from 'html-react-parser';

const SubpageInfo=({infoTitle,infoPara,infoImage,infoAlt})=>{
    return(
        <section className="sections-common-sec">
      <div className="info innr-info">
        <div className="container-fluid px-5">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="main-info">
                <div className="info-text">
                  <h3>{infoTitle}</h3>
                  <div className="subtext">{infoPara && parse(infoPara)}</div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="main-img-plot">
                <img src={infoImage} alt={infoAlt} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>    
    );
}

export default SubpageInfo;