import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import SiteHeader from "../Header/SiteHeader";
import MetaData from "../MetadataComp/MetaData";
const NotFoundPage = () =>{
  const [title] = useState("Error: 404");
  const [description] = useState("Page Not Found");
  const [keywords] = useState("Error 404");
    return (
        <>
        <MetaData 
          title={title}
          description={description}
          keywords={keywords}
        />
          <SiteHeader />
          <div className="error-page">
          <h1>404</h1>
          <h3>OOPS! PAGE NOT FOUND</h3>
          <p>Sorry the page you are looking for does not exist !</p>
          <NavLink className="primary-link primary-link--dark" to="/">
                Return To Home<svg
                  viewBox="0 0 33 14"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-labelledby="Long Arrow"
                  role="presentation"
                  className="svg-icon"
                >
                  <title id="Long Arrow" lang="en">
                    Long Arrow icon
                  </title>
                  <polygon
                    points="25.1604773 0 32.6352213 6.85610681 25.1993812 13.7359725 24.3690601 12.8391022 30.1441884 7.49516092 0.0024154589 7.43569437 0 6.21588761 30.2001884 6.27516092 24.3378796 0.897439017"
                    stroke="none"
                    strokeWidth="1"
                    fill="currentColor"
                    fillRule="evenodd"
                  ></polygon>
                </svg>
              </NavLink>
              </div>
        </>
      );
}

export default NotFoundPage;