import React, { useState } from "react";
import SiteHeader from "../Header/SiteHeader";
import SearchPageComp from "./SearchPageComp";
import Footer from "../Footer/Footer";
import MetaData from "../MetadataComp/MetaData";


const Search = () => {
    const [title] = useState("Search");
    const [description] = useState("Search and get the relative results published on ArcelorMittal International North America.");
    const [keywords] = useState("Search, Find information of ArcelorMittal International North America");
    return(
        <>
        <MetaData 
        title={title}
        description={description}
        keywords={keywords}
      />
        <SiteHeader />
        <SearchPageComp />
        <Footer />
        </>
    );
}

export default Search;