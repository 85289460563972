import React, {useState, useEffect} from "react";
import SiteHeader from "../../Header/SiteHeader";
import SubpageBanner from "../../Banner/SubpageBanner";
import Footer from "../../Footer/Footer";
import Info from "../../Info/Info";
import SubpageGrid from "../../Grid/SubpgeGrid";
import WireRopesAccordian from "../../Info/WireRopesAccordian";
import { ApiWireRopes, ApiFilterWireSol, ApiFilterHome } from "../../API/API";
import MetaData from "../../MetadataComp/MetaData";

const WireRopes = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [title] = useState("Wire Ropes - ArcelorMittal International North America");
  const [description] = useState("ArcelorMittal WireSolutions manufactures and supplies some of the most technologically advanced steel wire ropes in the world. Designed, developed, and manufactured for strength, flexibility, and endurance, ArcelorMittal WireSolutions delivers lasting value and safety for the most demanding environments.");
  const [keywords] = useState("Premium quality steel wire ropes for the hoisting, mining, ropeway, and mooring sectors");
  
  const [isLoadingBannerData, setIsLoadingBannerData] = useState(true);
  const [bannerData, setBannerData] = useState([
    {
      id: null,
      title: null,
    },
  ]);

  useEffect(() => {
   
  }, []);


//API fetch for info block 1 (wire rope block)
const [isLoadingInfoData1, setIsLoadingInfoData1] = useState(true);
const [infoData1, setinfoData1] = useState([
    {
      id: 0,
      infoTitle: null,
      infoImage: null,
      infoPara: null,
      infoAlt: null,
      // isButtonEnabled: false,
    },
  ]);

  useEffect(() => {
    ApiWireRopes(apiUrl)
      .then((response) => {
        if (
          response.data.data.attributes.section1_details
        ) {
          const item = response.data.data.attributes.section1_details;

          const newInfoData1 = {
            id: item.id,
            infoTitle: item.title,
            infoPara: item.description,
            infoImage: `${item.image.data.attributes.url}`,
            infoUrl: item.page_url,
            infoAlt: item.img_alt_txt
          };
          setinfoData1(newInfoData1);
          setIsLoadingInfoData1(false);
          ApiFilterWireSol(item.page_url)
          .then((response) => {
            if (response.data.data.attributes.businessline_block_item.length>0) {
              const item = response.data.data.attributes.businessline_block_item[0];
    
              const newBannerData = {
                id: item.id,
                title: item.title_text,
              };
    
              setBannerData(newBannerData);
              setIsLoadingBannerData(false);
            } 
            // else {
            //   console.log(response.data);
            // }
          })
          .catch((error) => {
            console.error("Error fetching data: ", error);
          });
        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[apiUrl]);
  
   const [isLoadingBannerBreadcrumb, setIsLoadingBannerBreadcrumb] = useState(true);
   const [bannerBreadcrumb, setBannerBreadcrumb] = useState([
    {
      id: null,
      pageLink: null,
      linkTitle: null
    },
  ]);
  
  useEffect(() => {
    ApiFilterHome("/wire-solutions")
      .then((response) => {
        if (response.data.data.attributes.businessline_block_item.length>0) {
          const item = response.data.data.attributes.businessline_block_item[0] ;
  
          const newBannerBreadcrumb = {
            id: item.id,
            pageLink: item.url,
            linkTitle: item.title_text
          };
  
          setBannerBreadcrumb(newBannerBreadcrumb);
          setIsLoadingBannerBreadcrumb(false);
        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[]);


  //API fetch for info block 2 (Rope Brochures)

const [isLoadingInfoData2, setIsLoadingInfoData2] = useState(true);
const [infoData2, setinfoData2] = useState([
    {
      id: 0,
      infoTitle: null,
      infoPara: null,
      // isButtonEnabled: false,
    },
  ]);

  useEffect(() => {
    ApiWireRopes(apiUrl)
      .then((response) => {
        if (
          response.data.data.attributes.section2_details
        ) {
          const item = response.data.data.attributes.section2_details;

          const newInfoData2 = {
            id: item.id,
            infoTitle: item.title,
            infoPara: item.description,
            // isButtonEnabled: false
          };
          setinfoData2(newInfoData2);
          setIsLoadingInfoData2(false);
        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[apiUrl]);
  

//API fetch for Accordian
  const [isLoadingAccordionItems, setIsLoadingAccordionItems] = useState(true);
  const [accordionItems, setAccordionItems] = useState([
    {
      id: 0,
      header: null,
      content: null,
    },
  ]);

  useEffect(() => {
    ApiWireRopes(apiUrl)
      .then((response) => {
        if (response.data.data.attributes.wiresol_accord) {
          const data_ac = response.data.data.attributes.wiresol_accord;
          const newAccordionItems = data_ac.map((item) => {
            return {
              id: item.order_no,
              header: item.title,
              content: item.description
            };
          });
          setIsLoadingAccordionItems(false);
          setAccordionItems(newAccordionItems);
        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[apiUrl]);
  

  //Api fetch for Horizontal SubGrid

  const [isLoadingGridData, setIsLoadingGridData] = useState(true);
  const [gridData, setGridData] = useState([
    {
      id: 0,
      para1: null,
      para2: null,
    },
  ]);

  useEffect(() => {
    ApiWireRopes(apiUrl)
      .then((response) => {
        if (response.data.data.attributes.horizontal_content) {
          const item = response.data.data.attributes.horizontal_content;

          const newGridData = {
            id: item.id,
            para1: item.left_description,
            para2: item.right_description,
          };
          setGridData(newGridData);
          setIsLoadingGridData(false);
        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[apiUrl]);

  const [accordianTitle, setAccordianTitle] = useState(null);
  useEffect(() => {
    ApiWireRopes(apiUrl)
      .then((response) => {
        if (response.data.data.attributes) {
          const item = response.data.data.attributes;
         setAccordianTitle(item.acrd_ttl)
        }
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  if (
    isLoadingBannerData || isLoadingBannerBreadcrumb|| isLoadingInfoData1 || isLoadingInfoData2 || isLoadingGridData ||isLoadingAccordionItems
  ) {
    return (
      <div className="loading-animation">
        <span className="loader"></span>
      </div>
    );
  }

  return (
    <>
    <MetaData
        title={title}
        description={description}
        keywords={keywords}
      />
      <SiteHeader />
      <SubpageBanner {...bannerData} {...bannerBreadcrumb} />
      <Info {...infoData1} />
      <WireRopesAccordian items={accordionItems} title={accordianTitle} />
      <div className="logistics-cont">
      <SubpageGrid {...gridData} />
      </div>
      <div className="wo-imgsec">
        <Info {...infoData2} />
      </div>
      <Footer />
    </>
  );
};

export default WireRopes;
