import React from "react";
import parse from "html-react-parser";

const LandingBanner = ({ bannerImage, bannerTitle, bannerDescription, bannerAlt }) => {
  return (
    <section className="banner-section">
      <div className="landing-banner-item">
        <img className="main-img d-block w-100" src={bannerImage} alt={bannerAlt}/>
        <div className="banner-contentsec">
          <div className="container-fluid px-5">
            <div className="row">
              <div className="col-md-11">
                <h1>{bannerTitle}</h1>
                {bannerDescription && parse(bannerDescription)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingBanner;
