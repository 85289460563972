import React, { useState, useEffect } from "react";
import { Accordion, Container, Table, Row, Col } from "react-bootstrap";
import {
  ApiA913Steel,
  ApiMechTable,
  ApiWeldTable1,
  ApiWeldTable2,
} from "../API/API";
import parse from "html-react-parser";

const A913AccordianTable = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  
  //API call for all the headings
  const [mainTitle, setMainTitle] = useState(null);
  const [a913Title, setA913Title] = useState(null);
  const [processTitle, setProcessTitle] = useState(null);
  const [weightTitle, setWeightTitle] = useState(null);
  const [weldabilityTitle, setWeldabilityTitle] = useState(null);
  const [toughTitle, setToughTitle] = useState(null);
  const [codeTitle, setCodeTitle] = useState(null);
  const [seismicTitle, setSeismicTitle] = useState(null);
  const [availTitle, setAvailTitle] = useState(null);
  useEffect(() => {
    ApiA913Steel(apiUrl)
      .then((response) => {
        if (response.data.data.attributes) {
          const item = response.data.data.attributes;
          setMainTitle(item.sec2_mn_hd);
          setA913Title(item.a913_acrd_hd);
          setProcessTitle(item.process_acrd_hd);
          setWeightTitle(item.weight_acrd_hd);
          setWeldabilityTitle(item.weld_acrd_hd);
          setToughTitle(item.tough_acrd_hd);
          setCodeTitle(item.code_acrd_hd);
          setSeismicTitle(item.seismic_acrd_hd);
          setAvailTitle(item.avl_acrd_hd);
        }
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  //api for process
  const [isLoadingProcessData, setIsLoadingProcessData] = useState(true);
  const [processData, setProcessData] = useState([
    {
      id: 0,
      processDesc: null,
      processImage: null,
      alt: null,
    },
  ]);
  useEffect(() => {
    ApiA913Steel()
      .then((response) => {
        if (response.data.data.attributes.process_acrd_dtls) {
          const item = response.data.data.attributes.process_acrd_dtls;

          const newProcessData = {
            id: item.id,
            processDesc: item.description,
            processImage: `${item.image.data.attributes.url}`,
            alt: item.img_alt_txt
          };

          setProcessData(newProcessData);
          setIsLoadingProcessData(false);
        }
        // else {
        //   console.log("No data found.");
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  //api for Toughness
  const [isLoadingToughData, setIsLoadingToughData] = useState(true);
  const [toughData, setToughData] = useState([
    {
      id: 0,
      toughDesc: null,
      toughImage: null,
      alt: null,
    },
  ]);
  useEffect(() => {
    ApiA913Steel()
      .then((response) => {
        if (response.data.data.attributes.tough_acrd_dtls) {
          const item = response.data.data.attributes.tough_acrd_dtls;

          const newToughData = {
            id: item.id,
            toughDesc: item.description,
            toughImage: `${item.image.data.attributes.url}`,
            alt: item.img_alt_txt
          };

          setToughData(newToughData);
          setIsLoadingToughData(false);
        }
        // else {
        //   console.log("No data found.");
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  //API for code
  const [isLoadingCodeData, setIsLoadingCodeData] = useState(true);
  const [codeData, setCodeData] = useState([
    {
      id: 0,
      codeDesc: null,
      codeImage: null,
      alt:null,
    },
  ]);
  useEffect(() => {
    ApiA913Steel()
      .then((response) => {
        if (response.data.data.attributes.code_acrd_dtls) {
          const item = response.data.data.attributes.code_acrd_dtls;

          const newCodeData = {
            id: item.id,
            codeDesc: item.description,
            codeImage: `${item.image.data.attributes.url}`,
            alt: item.img_alt_txt
          };

          setCodeData(newCodeData);
          setIsLoadingCodeData(false);
        }
        // else {
        //   console.log("No data found.");
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  //Api for Seismic
  const [isLoadingSeismicData, setIsLoadingSeismicData] = useState(true);
  const [seismicData, setSeismicData] = useState([
    {
      id: 0,
      seismicDesc: null,
    },
  ]);
  useEffect(() => {
    ApiA913Steel()
      .then((response) => {
        if (response.data.data.attributes.seismic_acrd_dtls) {
          const item = response.data.data.attributes.seismic_acrd_dtls;

          const newSeismicData = {
            id: item.id,
            seismicDesc: item.description,
          };

          setSeismicData(newSeismicData);
          setIsLoadingSeismicData(false);
        }
        // else {
        //   console.log("No data found.");
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  //API for availability
  const [isLoadingAvailData, setIsLoadingAvailData] = useState(true);
  const [availData, setAvailData] = useState([
    {
      id: 0,
      availDesc: null,
      availImage: null,
      alt: null,
    },
  ]);
  useEffect(() => {
    ApiA913Steel()
      .then((response) => {
        if (response.data.data.attributes.avl_acrd_dtls) {
          const item = response.data.data.attributes.avl_acrd_dtls;

          const newAvailData = {
            id: item.id,
            availDesc: item.description,
            availImage: `${item.image.data.attributes.url}`,
            alt: item.img_alt_txt
          };

          setAvailData(newAvailData);
          setIsLoadingAvailData(false);
        }
        // else {
        //   console.log("No data found.");
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);
  //Api for weight
  const [isLoadingWeightData, setIsLoadingWeightData] = useState(true);
  const [weightData, setWeightData] = useState([
    {
      id: 0,
      weightDesc: null,
      weightImage: null,
      weightCredit: null,
      weightAlt: null
    },
  ]);

  useEffect(() => {
    ApiA913Steel(apiUrl)
      .then((response) => {
        if (response.data.data.attributes.weight_acrd_dtls) {
          const data = response.data.data.attributes.weight_acrd_dtls;
          const newWeightData = data.map((item) => {
            return {
              id: item.id,
              weightDesc: item.description,
              weightImage: `${item.image.data.attributes.url}`,
              weightCredit: item.img_credit,
              weightAlt: item.img_alt_txt
            };
          });
          setWeightData(newWeightData);
          setIsLoadingWeightData(false);
        }
        //  else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  //API for weldability
  const [isLoadingWeldData, setIsLoadingWeldData] = useState(true);
  const [weldData, setWeldData] = useState([
    {
      id: 0,
      weldDesc: null,
      weldImage: null,
      weldCredit: null,
      alt: null,
    },
  ]);
  useEffect(() => {
    ApiA913Steel()
      .then((response) => {
        if (response.data.data.attributes.weld_acrd_dtls1) {
          const item = response.data.data.attributes.weld_acrd_dtls1;

          const newWeldData = {
            id: item.id,
            weldDesc: item.description,
            weldImage: `${item.image.data.attributes.url}`,
            weldCredit: item.img_credit,
            alt: item.img_alt_txt
          };

          setWeldData(newWeldData);
          setIsLoadingWeldData(false);
        }
        // else {
        //   console.log("No data found.");
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  //API call for mechanical table
  const [isLoadingMechTableData, setIsLoadingMechTableData] = useState(true);
  const [mechTableData, setMechTableData] = useState([
    {
      id: 0,
      Grade: null,
      A992: null,
      A913_Gr_50: null,
      A913_Gr_65: null,
      A913_Gr_70: null,
      A913_Gr_80: null,
    },
  ]);

  useEffect(() => {
    ApiMechTable(apiUrl)
      .then((response) => {
        if (response.data.data) {
          const data = response.data.data;
          const newMechTableData = data.map((item) => {
            return {
              id: item.id,
              Grade: item.attributes.Grade,
              A992: item.attributes.A992,
              A913_Gr_50: item.attributes.A913_Gr_50,
              A913_Gr_65: item.attributes.A913_Gr_65,
              A913_Gr_70: item.attributes.A913_Gr_70,
              A913_Gr_80: item.attributes.A913_Gr_80,
            };
          });
          setMechTableData(newMechTableData);
          setIsLoadingMechTableData(false);
        }
        //  else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  //API call for welddbility table 1
  const [isLoadingWeldTable1Data, setIsLoadingWeldTable1Data] = useState(true);
  const [weldTable1Data, setWeldTable1Data] = useState([
    {
      id: 0,
      Grade: null,
      A992_Gr_50: null,
      A913_Gr_50: null,
      A913_Gr_65: null,
      A913_Gr_70: null,
      A913_Gr_80: null,
    },
  ]);

  useEffect(() => {
    ApiWeldTable1(apiUrl)
      .then((response) => {
        if (response.data.data) {
          const data = response.data.data;
          const newWeldTable1Data = data.map((item) => {
            return {
              id: item.id,
              Grade: item.attributes.NAME,
              A992_Gr_50: item.attributes.A992_Gr_50,
              A913_Gr_50: item.attributes.A913_Gr_50,
              A913_Gr_65: item.attributes.A913_Gr_65,
              A913_Gr_70: item.attributes.A913_Gr_70,
              A913_Gr_80: item.attributes.A913_Gr_80,
            };
          });
          setWeldTable1Data(newWeldTable1Data);
          setIsLoadingWeldTable1Data(false);
        }
        //  else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  //API call for weldability table 2
  const [isLoadingWeldTable2Data, setIsLoadingWeldTable2Data] = useState(true);
  const [weldTable2Data, setWeldTable2Data] = useState([
    {
      id: 0,
      Thickness: null,
      A913_Gr_50_Gr_65: null,
      A913_Gr_70: null,
      A913_Gr_80: null,
    },
  ]);

  useEffect(() => {
    ApiWeldTable2(apiUrl)
      .then((response) => {
        if (response.data.data) {
          const data = response.data.data;
          const newWeldTable2Data = data.map((item) => {
            return {
              id: item.id,
              Thickness: item.attributes.Thickness,
              A913_Gr_50_Gr_65: item.attributes.A913_Gr_50_Gr_65,
              A913_Gr_70: item.attributes.A913_Gr_70,
              A913_Gr_80: item.attributes.A913_Gr_80,
            };
          });
          setWeldTable2Data(newWeldTable2Data);
          setIsLoadingWeldTable2Data(false);
        }
        //  else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  //a913 table info
  const [isLoadingA913MechData, setIsLoadingA913MechData] = useState(true);
  const [a913MechData, setA913MechData] = useState([
    {
      id: 0,
      tableInfo: null,
    },
  ]);
  useEffect(() => {
    ApiA913Steel()
      .then((response) => {
        if (response.data.data.attributes.a913_table) {
          const item = response.data.data.attributes.a913_table;

          const newA913MechData = {
            id: item.id,
            tableInfo: item.table_info1,
          };

          setA913MechData(newA913MechData);
          setIsLoadingA913MechData(false);
        }
        // else {
        //   console.log("No data found.");
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);
  //api for weld table 1 info
  const [isLoadingWeldData1, setIsLoadingWeldData1] = useState(true);
  const [weldData1, setWeldData1] = useState([
    {
      id: 0,
      tableInfo: null,
      tableTitle: null,
    },
  ]);
  useEffect(() => {
    ApiA913Steel()
      .then((response) => {
        if (response.data.data.attributes.weld_acrd_table1) {
          const item = response.data.data.attributes.weld_acrd_table1;

          const newWeldData1 = {
            id: item.id,
            tableTitle: item.mn_tbl_hd1,
            tableInfo: item.table_info1,
          };

          setWeldData1(newWeldData1);
          setIsLoadingWeldData1(false);
        }
        // else {
        //   console.log("No data found.");
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);
  //api for weld table 2 info
  const [isLoadingWeldData2, setIsLoadingWeldData2] = useState(true);
  const [weldData2, setWeldData2] = useState([
    {
      id: 0,
      tableInfo1: null,
      tableTitle1: null,
      tableTitle2: null,
      tableInfo2: null,
    },
  ]);
  useEffect(() => {
    ApiA913Steel()
      .then((response) => {
        if (response.data.data.attributes.weld_acrd_table2) {
          const item = response.data.data.attributes.weld_acrd_table2;

          const newWeldData2 = {
            id: item.id,
            tableTitle1: item.mn_tbl_hd1,
            tableInfo1: item.table_info1,
            tableTitle2: item.mn_tbl_hd2,
            tableInfo2: item.table_info2,
          };

          setWeldData2(newWeldData2);
          setIsLoadingWeldData2(false);
        }
        // else {
        //   console.log("No data found.");
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  if (
    isLoadingA913MechData ||
    isLoadingAvailData ||
    isLoadingCodeData ||
    isLoadingMechTableData ||
    isLoadingProcessData ||
    isLoadingSeismicData ||
    isLoadingToughData ||
    isLoadingWeightData ||
    isLoadingWeldData ||
    isLoadingWeldData1 ||
    isLoadingWeldData2 ||
    isLoadingWeldTable1Data ||
    isLoadingWeldTable2Data
  ) 
  {
    return (
      <div className="loading-animation1">
        <span className="loader"></span>
      </div>
    );
  }
  return (
    <section className="st-gallsec">
      <Container fluid className="px-5">
        <h3 className="text-center">{mainTitle}</h3>
        <div className="st-accodian-sec">
          <div className="col-md-12">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>{a913Title}</Accordion.Header>
                <Accordion.Body>
                  <div className="a93-pgtable mn-table">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Grade</th>
                          <th>A992</th>
                          <th>A913 Gr 50</th>
                          <th>A913 Gr 65</th>
                          <th>A913 Gr 70</th>
                          <th>A913 Gr 80</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mechTableData.map((item, index) => (
                          <tr key={index}>
                            <td>{item.Grade}</td>
                            <td>{item.A992}</td>
                            <td>{item.A913_Gr_50}</td>
                            <td>{item.A913_Gr_65}</td>
                            <td>{item.A913_Gr_70}</td>
                            <td>{item.A913_Gr_80}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <p className="table-info-dt">{a913MechData.tableInfo}</p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>{processTitle}</Accordion.Header>
                <Accordion.Body>
                  <div className="info-multi">
                    <Row className="align-items-center">
                      <Col md={7}>
                        <div className="main-info">
                          <div className="info-text">
                            {processData.processDesc &&
                              parse(processData.processDesc)}
                          </div>
                        </div>
                      </Col>
                      <Col md={5}>
                        <div className="main-img-plot">
                          <img src={processData.processImage} alt={processData.alt} />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>{weightTitle}</Accordion.Header>
                <Accordion.Body>
                  <div className="info-multi">
                    {weightData.map((item) => (
                      <Row className="align-items-center" key={item.id}>
                        <Col md={7}>
                          <div className="main-info">
                            <div className="info-text">
                              {item.weightDesc && parse(item.weightDesc)}
                            </div>
                          </div>
                        </Col>
                        <Col md={5}>
                          <div className="main-img-plot">
                            <img
                              src={item.weightImage}
                              alt={item.weightAlt}
                            />
                            <span className="image-credit">
                              {item.weightCredit && parse(item.weightCredit)}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>{weldabilityTitle}</Accordion.Header>
                <Accordion.Body>
                  <div className="info-multi">
                    <Row className="align-items-center">
                      <Col md={12}>
                        <div className="a93-weldability a93-pgtable mn-table">
                          <Table responsive>
                            <thead>
                              <tr>
                                <th></th>
                                <th colSpan={5} style={{ textAlign: "center" }}>
                                  {weldData1.tableTitle}
                                </th>
                              </tr>
                              <tr>
                                <th></th>
                                <th>A992 Gr 50</th>
                                <th>A913 Gr 50</th>
                                <th>A913 Gr 65</th>
                                <th>A913 Gr 70</th>
                                <th>A913 Gr 80</th>
                              </tr>
                            </thead>
                            <tbody>
                              {weldTable1Data.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.Grade}</td>
                                  <td>{item.A992_Gr_50}</td>
                                  <td>{item.A913_Gr_50}</td>
                                  <td>{item.A913_Gr_65}</td>
                                  <td>{item.A913_Gr_70}</td>
                                  <td>{item.A913_Gr_80}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          <p className="table-info-dt">{weldData1.tableInfo}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-center">
                      <Col md={7}>
                        <div className="main-info">
                          <div className="info-text">
                            {weldData.weldDesc && parse(weldData.weldDesc)}
                          </div>
                        </div>
                      </Col>
                      <Col md={5}>
                        <div className="main-img-plot">
                          <img src={weldData.weldImage} alt={weldData.alt} />
                          <span className="image-credit">
                            {weldData.weldCredit && parse(weldData.weldCredit)}
                          </span>
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="a93-weldability a93-pgtable mn-table">
                          <Table responsive>
                            <thead>
                              <tr>
                                <th colSpan={4}>{weldData2.tableTitle1}</th>
                              </tr>
                              <tr>
                                <th colSpan={4}>{weldData2.tableTitle2}</th>
                              </tr>
                              <tr>
                                <th>Thickness</th>
                                <th>A913 Gr 50 + Gr 65*</th>
                                <th>A913 Gr 70*</th>
                                <th>A913 Gr 80**</th>
                              </tr>
                            </thead>
                            <tbody>
                              {weldTable2Data.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.Thickness}</td>
                                  <td>{item.A913_Gr_50_Gr_65}</td>
                                  <td>{item.A913_Gr_70}</td>
                                  <td>{item.A913_Gr_80}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          <p className="table-info-dt">
                            {weldData2.tableInfo1}
                          </p>
                          <p className="table-info-dt">{weldData2.tableInfo2}</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>{toughTitle}</Accordion.Header>
                <Accordion.Body>
                  <div className="info-multi">
                    <Row className="align-items-center">
                      <Col md={7}>
                        <div className="main-info">
                          <div className="info-text">
                            {toughData.toughDesc && parse(toughData.toughDesc)}
                          </div>
                        </div>
                      </Col>
                      <Col md={5}>
                        <div className="main-img-plot">
                          <img src={toughData.toughImage} alt={toughData.alt} />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5">
                <Accordion.Header>{codeTitle}</Accordion.Header>
                <Accordion.Body>
                  <div className="info-multi">
                    <Row className="align-items-center">
                      <Col md={7}>
                        <div className="main-info">
                          <div className="info-text">
                            {codeData.codeDesc && parse(codeData.codeDesc)}
                          </div>
                        </div>
                      </Col>
                      <Col md={5}>
                        <div className="main-img-plot">
                          <img src={codeData.codeImage} alt={codeData.alt} />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6">
                <Accordion.Header>{seismicTitle}</Accordion.Header>
                <Accordion.Body>
                  <div className="info-multi">
                    <Row className="align-items-center">
                      <Col md={12}>
                        <div className="main-info">
                          <div className="info-text subtext">
                            {seismicData.seismicDesc &&
                              parse(seismicData.seismicDesc)}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="7">
                <Accordion.Header>{availTitle}</Accordion.Header>
                <Accordion.Body>
                  <div className="info-multi">
                    <Row className="align-items-center">
                      <Col md={7}>
                        <div className="main-info">
                          <div className="info-text">
                            {availData.availDesc && parse(availData.availDesc)}
                          </div>
                        </div>
                      </Col>
                      <Col md={5}>
                        <div className="main-img-plot">
                          <img src={availData.availImage} alt={availData.alt} />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Container>
    </section>
  );
};
export default A913AccordianTable;
