import React from "react";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/custom-style.css";
import "../../assets/css/variable.css";
import "../../assets/css/slick-carousel/slick/slick.css";
import "../../assets/css/slick-carousel/slick/slick-theme.css";
import calender from "../../assets/images/calender.svg";
import { NavLink } from "react-router-dom";
import moment from 'moment-timezone';
// import { ApiLatestNews, ApiHomeLatestNews } from "../API/API";

const LatestNews = ({ newsTitle, newsData }) => {
  const newsDtlUrl = process.env.REACT_APP_NEWSD_URL;

  const formatDate = (dateString) => {

    const timeZone = 'Europe/Paris'; // CET timezone

    const date = moment.tz(dateString, timeZone);
  
    const day = date.format('DD');
    const month = date.format('MMMM'); // Full month name
    const year = date.format('YYYY');
    //const hours = date.format('HH');
    //const minutes = date.format('mm');
  
    //return `${day} ${month} ${year} ${hours}:${minutes}`;
    return `${month} ${day}, ${year}`;
  };

  return (
    <section className="latest-news">
      <div className="container-fluid px-5">
        <div className="row">
          <div className="col-md-12">
            <div className="news-head text-center">
              <h3>{newsTitle}</h3>
            </div>
          </div>
        </div>
        <div className="main-news">
          <div className="row justify-content-center">
            {newsData.map((newsItem, index) => (
              <div key={index} className="col-md-4">
                <div className="main-nw">
                  <div className="nws-img">
                    <img src={newsItem.attributes.news_thumbnail.data.attributes.url} alt={newsItem.attributes.news_title} />
                  </div>
                  <div className="nws">
                    <div className="nws-date">
                      <img src={calender} alt="Calender" />
                      <p>{formatDate(newsItem.attributes.publishedAt)}</p>
                    </div>
                    <div className="nws-content">
                      <p>{newsItem.attributes.news_title}</p>
                      <NavLink className="site-btn1" to={`${newsDtlUrl}${newsItem.id}`}>
                        Read More
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="view-more-btnsec">
              <NavLink className="primary-link primary-link--dark" to="/latest-news">
                View More<svg
                  viewBox="0 0 33 14"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-labelledby="Long Arrow"
                  role="presentation"
                  className="svg-icon"
                >
                  <title id="Long Arrow" lang="en">
                    Long Arrow icon
                  </title>
                  <polygon
                    points="25.1604773 0 32.6352213 6.85610681 25.1993812 13.7359725 24.3690601 12.8391022 30.1441884 7.49516092 0.0024154589 7.43569437 0 6.21588761 30.2001884 6.27516092 24.3378796 0.897439017"
                    stroke="none"
                    strokeWidth="1"
                    fill="currentColor"
                    fillRule="evenodd"
                  ></polygon>
                </svg>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
  
  export default LatestNews;