import React, {useState} from "react";
import calender from "../../assets/images/calender.svg";
import { Link } from "react-router-dom";
import moment from 'moment-timezone';

const BlogComp=({blogData})=>{
  const newsDtlUrl = process.env.REACT_APP_NEWSD_URL;

    const [itemsToShow, setItemsToShow] = useState(6);

    const handleLoadMore = () => {
      setItemsToShow(prevItems => prevItems + 6);
    };

    if (!blogData || !Array.isArray(blogData)) {
      return <div>Loading or invalid data...</div>; // Handle data loading or invalid data
    }


    const formatDate = (dateString) => {

      const timeZone = 'Europe/Paris'; // CET timezone
      const date = moment.tz(dateString, timeZone);
    
      // Format date and time
      const day = date.format('DD');
      const month = date.format('MMMM'); // Full month name
      const year = date.format('YYYY');
    
      return `${month} ${day}, ${year}`;
    };
    
  
   
    return(
        <section className="latest-news blogpage">
        <div className="container-fluid px-5">
          <div className="row">
            <div className="col-md-12">
              <div className="news-head text-center">
                <h3>Our News</h3>
              </div>
            </div>
          </div>
          <div className="main-news">
            <div className="row">
              {blogData.slice(0, itemsToShow).map((newsItem, index) => (
                <div key={index} className="col-md-4">
                  <div className="main-nw">
                    <div className="nws-img">
             
                      <img src={newsItem.attributes.news_thumbnail.data.attributes.url} alt={newsItem.attributes.news_title} />
                    </div>
                    <div className="nws">
                      <div className="nws-date">
                        <img src={calender} alt="Calender" />
                        <p>{formatDate(newsItem.attributes.publishedAt)}</p>
                      </div>
                      <div className="nws-content">
                        <p>{newsItem.attributes.news_title}</p>
                        <Link className="site-btn1" to={`${newsDtlUrl}${newsItem.id}`}>
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {itemsToShow >= blogData.length ||(
              <div className="view-more-btnsec text-start">
                <Link className="primary-link primary-link--dark" onClick={handleLoadMore}>
                  View More<svg
                    viewBox="0 0 33 14"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-labelledby="Long Arrow"
                    role="presentation"
                    className="svg-icon"
                  >
                    <title id="Long Arrow" lang="en">
                      Long Arrow icon
                    </title>
                    <polygon
                      points="25.1604773 0 32.6352213 6.85610681 25.1993812 13.7359725 24.3690601 12.8391022 30.1441884 7.49516092 0.0024154589 7.43569437 0 6.21588761 30.2001884 6.27516092 24.3378796 0.897439017"
                      stroke="none"
                      strokeWidth="1"
                      fill="currentColor"
                      fillRule="evenodd"
                    ></polygon>
                  </svg>
                </Link>
              </div>
          )}
        </div>
      </section>
    );
}

export default BlogComp;