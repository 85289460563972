import React, {useState, useEffect} from "react";
import SubpageBanner from "../../Banner/SubpageBanner";
import SiteHeader from "../../Header/SiteHeader";
import Footer from "../../Footer/Footer";
import Info from "../../Info/Info";
import { ApiStrucShp_Sustain, ApiFilterStrucShape, ApiFilterHome } from "../../API/API";
import MetaData from "../../MetadataComp/MetaData";

const Sustainability = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [title] = useState("Sustainability - ArcelorMittal International North America");
  const [description] = useState("Lowest Embodied Carbon Steel Sections Available on the Planet ArcelorMittal International North America. All ArcelorMittal steel sections supplied to North America are produced using recycled scrap in an electric arc furnace (EAF).");
  const [keywords] = useState("XCarb, Environmental Product Declaration (EPD), Embodied Carbon of Transportation, Certifications and Documentation");
  
  //Api for banner 
  const [isLoadingBannerData, setIsLoadingBannerData] = useState(true);
  const [bannerData, setBannerData] = useState([
    {
      id: null,
      title: null,
    },
  ]);

 //API fetch for info1 
const [isLoadingInfoData1, setIsLoadingInfoData1] = useState(true);
const [infoData1, setinfoData1] = useState([
    {
      id: 0,
      infoTitle: null,
      infoImage: null,
      infoPara: null,
      infoAlt: null
      // isButtonEnabled: false,
    },
  ]);

  useEffect(() => {
    ApiStrucShp_Sustain(apiUrl)
      .then((response) => {
        if (
          response.data.data.attributes.section1_details
        ) {
          const item = response.data.data.attributes.section1_details;

          const newInfoData1 = {
            id: item.id,
            infoTitle: item.title,
            infoPara: item.description,
            infoImage: `${item.image.data.attributes.url}`,
            infoUrl: item.page_url,
            infoAlt: item.img_alt_txt
          };
          setinfoData1(newInfoData1);
          setIsLoadingInfoData1(false);
          ApiFilterStrucShape(item.page_url)
          .then((response) => {
            if (response.data.data.attributes.businessline_block_item.length>0) {
              const item = response.data.data.attributes.businessline_block_item[0];
    
              const newBannerData = {
                id: item.id,
                title: item.title_text,
              };
    
              setBannerData(newBannerData);
              setIsLoadingBannerData(false);
            } 
            // else {
            //   console.log(response.data);
            // }
          })
          .catch((error) => {
            console.error("Error fetching data: ", error);
          });
          
        }
        //  else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[apiUrl]);
  
  const [isLoadingBannerBreadcrumb, setIsLoadingBannerBreadcrumb] = useState(true);
  const [bannerBreadcrumb, setBannerBreadcrumb] = useState([
    {
      id: null,
      pageLink: null,
      linkTitle: null
    },
  ]);

  useEffect(() => {
    ApiFilterHome("/structural-shapes")
      .then((response) => {
        if (response.data.data.attributes.businessline_block_item.length>0) {
          const item = response.data.data.attributes.businessline_block_item[0] ;

          const newBannerBreadcrumb = {
            id: item.id,
            pageLink: item.url,
            linkTitle: item.title_text
          };

          setBannerBreadcrumb(newBannerBreadcrumb);
          setIsLoadingBannerBreadcrumb(false);
        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[]);

  //API fetch for info2

  const [isLoadingInfoData2, setIsLoadingInfoData2] = useState(true);
  const [infoData2, setinfoData2] = useState([
    {
      id: 0,
      infoTitle: null,
      infoImage: null,
      infoPara: null,
      infoAlt: null,
      isDownloadButtonEnabled: null,
      downloadButtonLabel: null,
      fileLink: null,
    },
  ]);

  useEffect(() => {
    ApiStrucShp_Sustain(apiUrl)
      .then((response) => {
        if (
          response.data.data.attributes.section2_details
        ) {
          const item = response.data.data.attributes.section2_details;

          const fileLinks = item.pdf_items.map((pdfItem) => ({
            title: 'XCarb Brochure.pdf',
            url: `${pdfItem.pdf.data.attributes.url}`,
          }));
  

          const newInfoData2 = {
            id: item.id,
            infoTitle: item.title,
            infoPara: item.description,
            infoImage: `${item.image.data.attributes.url}`,
            infoAlt: item.img_alt_txt,
            isDownloadButtonEnabled: fileLinks.length > 0,
            downloadButtonLabel: fileLinks.map(link => link.title), 
            fileLinks: fileLinks,
            isReadEnabled: true,
          };
          setinfoData2(newInfoData2);
          setIsLoadingInfoData2(false);

        
        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[apiUrl]);
  

  //API fetch for info3

  const [isLoadingInfoData3, setIsLoadingInfoData3] = useState(true);
  const [infoData3, setinfoData3] = useState([
    {
      id: 0,
      infoTitle: null,
      infoImage: null,
      infoPara: null,
      infoAlt: null,
      isDownloadButtonEnabled: null,
      downloadButtonLabel: null,
      fileLink: null,
    },
  ]);

  useEffect(() => {
    ApiStrucShp_Sustain(apiUrl)
      .then((response) => {
        if (
          response.data.data.attributes.section3_details
        ) {
          const item = response.data.data.attributes.section3_details;

          const fileLinks = item.pdf_items.map((pdfItem) => ({
            title: 'EPD Structural Sections- Standard + XCarb UL.pdf',
            url: `${pdfItem.pdf.data.attributes.url}`,
          }));
  

          const newInfoData3 = {
            id: item.id,
            infoTitle: item.title,
            infoPara: item.description,
            infoImage: `${item.image.data.attributes.url}`,
            infoAlt: item.img_alt_txt,
            isDownloadButtonEnabled: fileLinks.length > 0,
            downloadButtonLabel: fileLinks.map(link => link.title), 
            fileLinks: fileLinks,
          };
          setinfoData3(newInfoData3);
          setIsLoadingInfoData3(false);

        }
        //  else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[apiUrl]);
  

  //API fetch for info4
  const [isLoadingInfoData4, setIsLoadingInfoData4] = useState(true);
  const [infoData4, setinfoData4] = useState([
    {
      id: 0,
      infoTitle: null,
      infoImage: null,
      infoPara: null,
      infoAlt: null,
      isDownloadButtonEnabled: null,
      downloadButtonLabel: null,
      fileLink: null,
    },
  ]);

  useEffect(() => {
    ApiStrucShp_Sustain(apiUrl)
      .then((response) => {
        if (
          response.data.data.attributes.section4_details
        ) {
          const item = response.data.data.attributes.section4_details;

          const fileLinks = item.pdf_items.map((pdfItem) => ({
            title: 'Embodied Carbon of Overseas Transport.pdf',
            url: `${pdfItem.pdf.data.attributes.url}`,
          }));
  

          const newInfoData4 = {
            id: item.id,
            infoTitle: item.title,
            infoPara: item.description,
            infoImage: `${item.image.data.attributes.url}`,
            infoAlt: item.img_alt_txt,
            isDownloadButtonEnabled: fileLinks.length > 0,
            downloadButtonLabel: fileLinks.map(link => link.title), 
            fileLinks: fileLinks,
            isReadEnabled: true,
          };
          setinfoData4(newInfoData4);
          setIsLoadingInfoData4(false);

   
        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[apiUrl]);
  


  //API fetch for info5
  const [isLoadingInfoData5, setIsLoadingInfoData5] = useState(true);
  const [infoData5, setinfoData5] = useState([
    {
      id: 0,
      infoTitle: null,
      infoImage: null,
      infoPara: null,
      infoAlt: null,
      // isButtonEnabled: false,
    },
  ]);

  useEffect(() => {
    ApiStrucShp_Sustain(apiUrl)
      .then((response) => {
        if (
          response.data.data.attributes.section5_details
        ) {
          const item = response.data.data.attributes.section5_details;

          const newInfoData5 = {
            id: item.id,
            infoTitle: item.title,
            infoPara: item.description,
            infoAlt: item.img_alt_txt,
            infoImage: `${item.image.data.attributes.url}`,
            // isButtonEnabled: false
          };
          setinfoData5(newInfoData5);
          setIsLoadingInfoData5(false);

        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[apiUrl]);

  
  if (
    isLoadingBannerData ||
    isLoadingBannerBreadcrumb ||
    isLoadingInfoData1 ||
    isLoadingInfoData2 ||
    isLoadingInfoData3 ||
    isLoadingInfoData4 ||
    isLoadingInfoData5 
  ) {
    return (
      <div className="loading-animation">
        <span className="loader"></span>
      </div>
    );
  }
  
  return (
    <>
    <MetaData 
        title={title}
        description={description}
        keywords={keywords}
      />
      <SiteHeader />
      <SubpageBanner {...bannerData} {...bannerBreadcrumb} />
      <section className="sections-common-sec sustainabilitysec">
        <Info {...infoData1} />
        <Info {...infoData2} />
        <Info {...infoData3} />
        <Info {...infoData4} />
        <Info {...infoData5} /> 
      </section>
      <Footer />
    </>
  );
};

export default Sustainability;
