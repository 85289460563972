import React, { useState, useEffect } from "react";
import SiteHeader from "../../Header/SiteHeader";
import Footer from "../../Footer/Footer";
import SubpageBanner from "../../Banner/SubpageBanner";
import SubpageInfo from "../../Info/SubpageInfo";
import SubpageGrid from "../../Grid/SubpgeGrid";
import PRTable from "../../Table/PRTable";
import {
  ApiProd_Size,
  ApiFilterStrucShape,
  ApiFilterHome,
} from "../../API/API";
import MetaData from "../../MetadataComp/MetaData";

const ProductSizeRange = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [title] = useState("Products and Size Range - ArcelorMittal International North America");
  const [description] = useState("ArcelorMittal offers the largest range of section sizes of any steel producer in the world, providing steel solutions for the most challenging projects. This includes the largest jumbo shapes produced globally, such as the W14x930, W14x1000, and W36x925. Explore our availability chart below to view the complete range of steel sizes we offer");
  const [keywords] = useState("Products and Size Range, Global Leaders in Structural Steel, Grade Availability, Length Capabilities, Shapes and Sizes");

  const [isLoadingBannerData, setIsLoadingBannerData] = useState(true);
  const [bannerData, setBannerData] = useState([
    {
      id: null,
      title: null,
    },
  ]);

  const [isLoadingInfoData, setIsLoadingInfoData] = useState(true);
  const [infoData, setinfoData] = useState([
    {
      id: 0,
      infoTitle: null,
      infoImage: null,
      infoPara: null,
      infoAlt: null
      // isButtonEnabled: false,
    },
  ]);

  useEffect(() => {
    ApiProd_Size(apiUrl)
      .then((response) => {
        if (response.data.data.attributes.section1_details) {
          const item = response.data.data.attributes.section1_details;

          const newInfoData = {
            id: item.id,
            infoTitle: item.title,
            infoPara: item.description,
            infoImage: `${item.image.data.attributes.url}`,
            infoUrl: item.page_url,
            infoAlt: item.img_alt_txt
          };
          setinfoData(newInfoData);
          setIsLoadingInfoData(false);
          ApiFilterStrucShape(item.page_url)
            .then((response) => {
              if (
                response.data.data.attributes.businessline_block_item.length > 0
              ) {
                const item =
                  response.data.data.attributes.businessline_block_item[0];

                const newBannerData = {
                  id: item.id,
                  title: item.title_text,
                };

                setBannerData(newBannerData);
                setIsLoadingBannerData(false);
              }
              // else {
              //   console.log(response.data);
              // }
            })
            .catch((error) => {
              console.error("Error fetching data: ", error);
            });
        }
        //  else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  const [isLoadingBannerBreadcrumb, setIsLoadingBannerBreadcrumb] =
    useState(true);
  const [bannerBreadcrumb, setBannerBreadcrumb] = useState([
    {
      id: null,
      pageLink: null,
      linkTitle: null,
    },
  ]);

  useEffect(() => {
    ApiFilterHome("/structural-shapes")
      .then((response) => {
        if (response.data.data.attributes.businessline_block_item.length > 0) {
          const item = response.data.data.attributes.businessline_block_item[0];

          const newBannerBreadcrumb = {
            id: item.id,
            pageLink: item.url,
            linkTitle: item.title_text,
          };

          setBannerBreadcrumb(newBannerBreadcrumb);
          setIsLoadingBannerBreadcrumb(false);
        }
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  const [isLoadingGridData, setIsLoadingGridData] = useState(true);
  const [gridData, setGridData] = useState([
    {
      id: 0,
      para1: null,
      para2: null,
    },
  ]);

  useEffect(() => {
    ApiProd_Size()
      .then((response) => {
        if (response.data.data.attributes.horizontal_block_product) {
          const item = response.data.data.attributes.horizontal_block_product;

          const newGridData = {
            id: item.id,
            para1: item.left_description,
            para2: item.right_description,
          };
          setGridData(newGridData);
          setIsLoadingGridData(false);
        }
        //  else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  if (
    isLoadingBannerData ||
    isLoadingBannerBreadcrumb ||
    isLoadingInfoData ||
    isLoadingGridData
  ) {
    return (
      <div className="loading-animation">
        <span className="loader"></span>
      </div>
    );
  }
  return (
    <>
    <MetaData 
        title={title}
        description={description}
        keywords={keywords}
      />
      <SiteHeader />
      <SubpageBanner {...bannerData} {...bannerBreadcrumb} />
      <SubpageInfo {...infoData} />
      <SubpageGrid {...gridData} />
      <PRTable />
      <Footer />
    </>
  );
};

export default ProductSizeRange;
