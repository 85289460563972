import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import BuisnessLine from "../BuisnessLine/BuisnessLine";
import MapSection from "../MapSection/MapSection";
import ContactSec from "../ContactSec/ContactSec";
import LandingBanner from "../Banner/LandingBanner";
import SiteHeader from "../Header/SiteHeader";
import SubpageGrid from "../Grid/SubpgeGrid";
import { ApiLogistics } from "../API/API";
import { useLocation } from "react-router-dom";
import MetaData from "../MetadataComp/MetaData";
const Logistics = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [apiDataLoaded, setApiDataLoaded] = useState(false);
  const location = useLocation();
  const sliderProps = {
    toShow: 4,
    toScroll: 1,
  };

  //Api fetch for banner
  const [isLoadingBanner, setIsLoadingBanner] = useState(true);
  const [LandingBannerData, setLandingBannerData] = useState([
    {
      id: 0,
      bannerImage: null,
      bannerAlt: null,
      bannerTitle: null,
      bannerDescription: null,
    },
  ]);

  useEffect(() => {
    ApiLogistics(apiUrl)
      .then((response) => {
        if (response.data.data.attributes.banner_section.length > 0) {
          const item = response.data.data.attributes.banner_section[0];

          const newBannerData = {
            id: item.id,
            bannerImage: `${item.main_img.data.attributes.url}`,
            bannerAlt: item.img_alt_title,
            bannerTitle: item.navigator_text,
            bannerDescription: item.title,
          };

          setLandingBannerData(newBannerData);
          setIsLoadingBanner(false);
        }
        // else {
        //   console.log("No data found.");
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  //Api fetch for buisnessline
  const [isLoadingBLine, setIsLoadingBLine] = useState(true);
  const [bLineData, setBLineData] = useState([
    {
      id: 0,
      name: null,
      image: null,
      alt: null,
      route: "",
    },
  ]);

  useEffect(() => {
    ApiLogistics(apiUrl)
      .then((response) => {
        if (response.data.data.attributes.businessline_block_item) {
          const data_bl = response.data.data.attributes.businessline_block_item;
          data_bl.sort((a, b) => a.order_no - b.order_no);
          const newBLineData = data_bl.map((item) => {
            return {
              id: item.order_no,
              name: item.title_text,
              route: item.url,
              alt: item.img_alt_title,
              image: `${item.bg_img.data.attributes.url}`,
            };
          });
          setIsLoadingBLine(false);
          setBLineData(newBLineData);
        }
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  //Api fetch for contact Data
  //Api fetch for contact heading1
  const [contactHeading1, setContactHeading1] = useState({ title: null });
  const [isLoadingContactHeading1, setIsLoadingContactHeading1] =
    useState(true);

  useEffect(() => {
    ApiLogistics()
      .then((response) => {
        const { attributes } = response.data.data;
        if (attributes && attributes.Contact_us1) {
          setContactHeading1({ title: attributes.Contact_us1 });
        }
        // else {
        //   console.log("Contact Heading 1 not found in API response.");
        // }
        setIsLoadingContactHeading1(false);
      })
      .catch((error) => {
        console.error("Error fetching contactHeading1 data: ", error);
      });
  }, []);

  //Api fetch for contact data 1
  const [isLoadingContactData1, setIsLoadingContactData1] = useState(true);
  const [contactData1, setContactData1] = useState([
    {
      item: {
        id: 0,
        names: null,
        countryCode: null,
        role: null,
        email: null,
        phone: null,
        address: null,
      },
    },
  ]);

  useEffect(() => {
    ApiLogistics()
      .then((response) => {
        if (
          response.data.data.attributes.Contact_us1 &&
          response.data.data.attributes.contact_us1_fields
        ) {
          const data1 = response.data.data.attributes.contact_us1_fields;
          const newContactData1 = data1.map((item1) => {
            return {
              item: {
                id: item1.id,
                names: item1.name,
                countryCode: item1.country_code,
                role: item1.role,
                email: item1.email,
                phone: item1.contact_no,
                address: item1.address,
              },
            };
          });
          setIsLoadingContactData1(false);
          setContactData1(newContactData1);
        }
        // else {
        //   console.log("Invalid response format:", response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  //Api fetch for grid comp
  const [isLoadingGridData, setIsLoadingGridData] = useState(true);
  const [gridData, setGridData] = useState([
    {
      id: 0,
      para1: null,
      para2: null,
    },
  ]);

  useEffect(() => {
    ApiLogistics()
      .then((response) => {
        if (response.data.data.attributes.logistics_side_by_side_contents) {
          const item =
            response.data.data.attributes.logistics_side_by_side_contents;

          const newGridData = {
            id: item.id,
            para1: item.left_description,
            para2: item.right_description,
          };
          setGridData(newGridData);
          setIsLoadingGridData(false);
        }
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  const [mapHeading, setMapHeading] = useState(null);
  useEffect(() => {
    ApiLogistics(apiUrl)
      .then((response) => {
        if (response.data.data.attributes) {
          const item = response.data.data.attributes;
          setMapHeading(item.ports_mn_title);
        }
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  const [contactTitle, setContactTitle] = useState(null);
  useEffect(() => {
    ApiLogistics(apiUrl)
      .then((response) => {
        if (response.data.data.attributes) {
          const item = response.data.data.attributes;
          setContactTitle(item.cont_sec_title);
        }
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [keywords, setKeyword] = useState(null);
  useEffect(() => {
    ApiLogistics()
      .then((response) => {
        if (response.data.data.attributes.seo) {
          const item = response.data.data.attributes.seo;
          setTitle(item.page_title);
          setDescription(item.meta_description);
          setKeyword(item.meta_keywords);
        }
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  });
  useEffect(() => {
    if (
      !isLoadingBanner &&
      !isLoadingBLine &&
      !isLoadingGridData &&
      !isLoadingContactHeading1 &&
      !isLoadingContactData1
    ) {
      setApiDataLoaded(true);

      const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      };

      setTimeout(() => {
        switch (location.hash) {
          case "#contact":
            scrollToSection("contact");
            break;
          case "#mapsec":
            scrollToSection("mapsec");
            break;
          case "#log-gal":
            scrollToSection("log-gal");
            break;
          default:
            break;
        }
      }, 1000); // Adjust the timeout duration as needed
    }
  }, [
    isLoadingBanner,
    isLoadingBLine,
    isLoadingGridData,
    isLoadingContactData1,
    isLoadingContactHeading1,
    location.hash,
  ]);
  useEffect(() => {
    if (
      !isLoadingBanner &&
      !isLoadingBLine &&
      !isLoadingGridData &&
      !isLoadingContactHeading1 &&
      !isLoadingContactData1
    ) {
      setApiDataLoaded(true);
    }
  }, [
    isLoadingBanner,
    isLoadingBLine,
    isLoadingGridData,
    isLoadingContactData1,
    isLoadingContactHeading1,
  ]);

  if (
    isLoadingBanner ||
    isLoadingBLine ||
    isLoadingContactData1 ||
    isLoadingContactHeading1 ||
    isLoadingGridData
  ) {
    return (
      <div className="loading-animation">
        <span className="loader"></span>
      </div>
    );
  }

  return (
    <>
     <MetaData 
        title={title}
        description={description}
        keywords={keywords}
      />
      <SiteHeader apiDataLoaded={apiDataLoaded} />
      <LandingBanner {...LandingBannerData} />
      <BuisnessLine
        bLineData={bLineData}
        {...sliderProps}
        className="logistic-bline"
        id="log-gal"
      />
      <div className="logistics-cont">
        <SubpageGrid {...gridData} />
      </div>
      <MapSection mapHeading={mapHeading} />
      <ContactSec
        contactHeading1={contactHeading1}
        contactData1={contactData1}
        contactTitle={contactTitle}
      />
      <Footer />
    </>
  );
};

export default Logistics;
