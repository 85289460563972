import React from "react";
import { Accordion } from "react-bootstrap";
import bag from "../../assets/images/bag.svg";
import call from "../../assets/images/call.svg";
import email from "../../assets/images/email.svg";
import ReactCountryFlag from "react-country-flag";

const ContactSec = ({
  contactHeading1,
  contactHeading2,
  contactData1,
  contactData2,
  contactTitle
}) => {
  return (
    <section className="contact-us" id="contact">
      <div className="container">
        <div className="srv-head">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <h3>{contactTitle}</h3>
            </div>
          </div>
        </div>
        <div className="contactgridsec">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {contactHeading1 && contactHeading1.title}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="contdet">
                      {contactData1.map((section, index) => (
                        <div key={index} className="contdet-row">
                          <div className="cdhead">
                            <p>
                              {section.item.names}{" "}
                              {section.item.address &&
                                ` - ${section.item.address}`}
                            </p> 
                            <ReactCountryFlag
                              countryCode={section.item.countryCode}
                              svg
                              alt={`Flag of ${section.item.countryCode}`} 
                            />
                          </div>
                          <div className="cdbody">
                            <ul>
                              {section.item.role && (
                                <li>
                                  <img src={bag} alt="ArcelorMittal International" />
                                  <p>{section.item.role}</p>
                                </li>
                              )}
                              {section.item.email && (
                                <li>
                                  <img src={email} alt="ArcelorMittal International" />
                                  <a href={`mailto:${section.item.email}`}>
                                    {section.item.email}
                                  </a>
                                </li>
                              )}

                              {section.item.phone && (
                                <li>
                                  <img src={call} alt="ArcelorMittal International" />
                                  <a href={`tel:${section.item.phone}`}>
                                    {section.item.phone}
                                  </a>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                {contactHeading2 && (
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>{contactHeading2.title}</Accordion.Header>
                    <Accordion.Body>
                      <div className="contdet">
                        {contactData2.map((section, index) => (
                          <div key={index} className="contdet-row">
                            <div className="cdhead">
                            <p>
                              {section.item.names}{" "}
                              {section.item.address &&
                                ` - ${section.item.address}`}
                            </p>
                              <ReactCountryFlag
                                countryCode={section.item.countryCode}
                                svg
                                alt={`Flag of ${section.item.countryCode}`}
                              />
                            </div>
                            <div className="cdbody">
                              <ul>
                                {section.item.role && (
                                  <li>
                                    <img src={bag} alt="ArcelorMittal International" />
                                    <p>{section.item.role}</p>
                                  </li>
                                )}
                                {section.item.email && (
                                  <li>
                                    <img src={email} alt="ArcelorMittal International" />
                                    <a href={`mailto:${section.item.email}`}>
                                      {section.item.email}
                                    </a>
                                  </li>
                                )}

                                {section.item.phone && (
                                  <li>
                                    <img src={call} alt="ArcelorMittal International" />
                                    <a href={`tel:${section.item.phone}`}>
                                      {section.item.phone}
                                    </a>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSec;
