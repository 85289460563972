import React, { useState, useEffect } from "react";
import check from "../../assets/images/check.png";
import error from "../../assets/images/error.png";
import Multiselect from "multiselect-react-dropdown";
import { ApiCategory, ApiSubcategory, ApiTableValue , ApiProd_Size } from "../API/API";

const PRTable = () => {


  const apiUrl = process.env.REACT_APP_API_URL;
  const [pdfLink, setPdfLink] = useState(null);
  const [pdfName, setPdfName] = useState(null);
  useEffect(() => {
    ApiProd_Size(apiUrl)
      .then((response) => {
        if (response.data.data.attributes.pdf_item) {
          const item = response.data.data.attributes.pdf_item;
          setPdfLink(item.pdf.data.attributes.url);
          setPdfName(item.pdf.data.attributes.name);
        }
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);



  const [heading, setHeading] = useState(null);

  useEffect(() => {
    ApiProd_Size()
      .then((response) => {
        if (response.data.data.attributes) {
          const item = response.data.data.attributes;
          setHeading(item.sttable_title);
        } 
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[]);

  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [categories, setCategories] = useState([
    {
      id: 0,
      name: null,
      subcategories: [],
    },
  ]);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await ApiCategory();
      if (response && response.data.data) {
        const data = response.data.data;
        const newCategories = data.map((item) => ({
          id: item.id,
          name: item.attributes.cat_name,
          subcategories: [],
        }));
        setIsLoadingCategories(false);
        setCategories(newCategories);
      } 
    };

    fetchCategories();
  }, []);

  // Fetch subcategories and map to categories
  useEffect(() => {
    if (!isLoadingCategories) {
      const fetchSubcategories = async () => {
        const response = await ApiSubcategory();
        if (response && response.data.data) {
          const subcategoriesData = response.data.data;

          const updatedCategories = categories.map((category) => {
            const subcategories = subcategoriesData
              .filter((subcat) =>
                subcat.attributes.ss_master_category_ids.data.some(
                  (cat) => cat.id === category.id
                )
              )
              .map((subcat) => subcat.attributes.subcat_name);
            return { ...category, subcategories };
          });

          setCategories(updatedCategories);
        } 
      };

      fetchSubcategories();
    }
  }, [isLoadingCategories]);


  const categoryHeaders = {
    "Wide Flange Sections": (
      <thead>
        <tr>
          <th>Section</th>
          <th>Weight</th>
          <th>A</th>
          <th>d</th>
          <th>b<sub>f</sub></th>
          <th>
            t<sub>w</sub>
          </th>
          <th>
            t<sub>f</sub>
          </th>
          <th colSpan="6" className="text-center">
            Availability
          </th>
        </tr>
        <tr>
          <th colSpan="2" className="text-end">
            lb/ft
          </th>
          <th>
            in<sup>2</sup>
          </th>
          <th>in.</th>
          <th>in.</th>
          <th>in.</th>
          <th>in.</th>
          <th>A992</th>
          <th>A913 Gr 50+65</th>
          <th>A913 Gr 70</th>
          <th>A913 Gr 80</th>
          <th>A709</th>
          <th>A588</th>
        </tr>
      </thead>
    ),
    "\"C\" Shapes": (
      <thead>
        <tr>
          <th>Section</th>
          <th>Weight</th>
          <th>A</th>
          <th>d</th>
          <th>
            b<sub>f</sub>
          </th>
          <th>
            t<sub>w</sub>
          </th>
          <th>
            t<sub>f</sub>
          </th>
          <th colSpan="1" className="text-center">
            Availability
          </th>
        </tr>
        <tr>
          <th colSpan="2" className="text-end">
            lb/ft
          </th>
          <th>
            in<sup>2</sup>
          </th>
          <th>in.</th>
          <th>in.</th>
          <th>in.</th>
          <th>in.</th>
          <th>A992</th>
        </tr>
      </thead>
    ),
    "\"MC\" Shapes": (
      <thead>
        <tr>
          <th>Section</th>
          <th>Weight</th>
          <th>A</th>
          <th>d</th>
          <th>
            b<sub>f</sub>
          </th>
          <th>
            t<sub>w</sub>
          </th>
          <th>
            t<sub>f</sub>
          </th>
          <th colSpan="3" className="text-center">
            Availability
          </th>
        </tr>
        <tr>
          <th colSpan="2" className="text-end">
            lb/ft
          </th>
          <th>
            in<sup>2</sup>
          </th>
          <th>in.</th>
          <th>in.</th>
          <th>in.</th>
          <th>in.</th>

          <th>A992</th>
          <th>A709</th>
          <th>A572 Gr60</th>
        </tr>
      </thead>
    ),
    "\"S\" Shapes": (
      <thead>
        <tr>
          <th>Section</th>
          <th>Weight</th>
          <th>A</th>
          <th>d</th>
          <th>
            b<sub>f</sub>
          </th>
          <th>
            t<sub>w</sub>
          </th>
          <th>
            t<sub>f</sub>
          </th>
          <th colSpan="2" className="text-center">
            Availability
          </th>
        </tr>
        <tr>
          <th colSpan="2" className="text-end">
            lb/ft
          </th>
          <th>
            in<sup>2</sup>
          </th>
          <th>in.</th>
          <th>in.</th>
          <th>in.</th>
          <th>in.</th>
          <th>A992</th>
          <th>A709</th>
        </tr>
      </thead>
    ),
    "\"L\" Shapes": (
      <thead>
        <tr>
          <th>Section</th>
          <th>Weight</th>
          <th>A</th>
          <th colSpan="5" className="text-center">
            Availability
          </th>
        </tr>
        <tr>
          <th colSpan="2" className="text-end">
            lb/ft
          </th>
          <th>
            in<sup>2</sup>
          </th>

          <th>A992</th>
          <th>A709</th>
          <th>A572 Gr50</th>
          <th>A572 Gr65</th>
          <th>A588</th>
        </tr>
      </thead>
    ),
    "\"HP\" Shapes": (
      <thead>
      <tr>
        <th>Section</th>
        <th>Weight</th>
        <th>A</th>
        <th>d</th>
        <th>
          b<sub>f</sub>
        </th>
        <th>
          t<sub>w</sub>
        </th>
        <th>
          t<sub>f</sub>
        </th>
        <th colSpan="3" className="text-center">
          Availability
        </th>
      </tr>
      <tr>
        <th colSpan="2" className="text-end">
          lb/ft
        </th>
        <th>
          in<sup>2</sup>
        </th>
        <th>in.</th>
        <th>in.</th>
        <th>in.</th>
        <th>in.</th>

        <th>A992</th>
        <th>A913 GR 50+65</th>
        <th>A913 GR 70</th>
        <th>A913 GR 80</th>
        <th>A709</th>
        <th>A588</th>
      </tr>
    </thead>
    ),
  };

  //API call
  
  const [isLoadingTableData, setIsLoadingTableData] = useState(true);
  const [tableData, setTableData] = useState([
    {
      id: 0,
      section: null,
      weight: null,
      a: null,
      d: null,
      b: null,
      bf: null,
      tw: null,
      tf: null,
      availability: {
        A992: null,
        A913_50: null,
        A913_80: null,
        A709: null,
        A588: null,
        A572_60: null,
        A572_50: null,
        A572_65: null,
      },
      category: null,
      subcategory: null,
    },
  ]);

  useEffect(() => {
    const fetchTableData = async () => {
      const response = await ApiTableValue();

      if (response && response.data.data) {
        const data = response.data.data;
      
        const newTableData = data.map((item) => {
        
          const parseBoolean = (value) => {
            if (value === "true") return true;
            if (value === "false") return false;
            return value;
          };
          return {
            id: item.id,
            section: item.attributes.section,
            weight: item.attributes.Weight,
            a: item.attributes.A,
            d: item.attributes.d,
            b: item.attributes.b,
            bf: item.attributes.bf,
            tw: item.attributes.tw,
            tf: item.attributes.tf,
            availability: {
              A992: parseBoolean(item.attributes.A992),
              A913_50: parseBoolean(item.attributes.A913_Gr_50add65),
              A913_70: parseBoolean(item.attributes.A913_Gr_70),
              A913_80: parseBoolean(item.attributes.A913_Gr_80),
              A709: parseBoolean(item.attributes.A709),
              A588: parseBoolean(item.attributes.A588),
              A572_60: parseBoolean(item.attributes.A572_Gr_60),
              A572_50: parseBoolean(item.attributes.A572_Gr_50),
              A572_65: parseBoolean(item.attributes.A572_Gr_65),
            },
            category: item.attributes?.master_category_id?.data?.attributes?.cat_name ?? "N/A",
            subcategory: item.attributes?.master_subcategory?.data?.attributes?.subcat_name ?? "N/A",
          };
        });

        // const sortedTableData = newTableData.sort((a, b) => {
        //   return b.section.localeCompare(a.section, undefined, { numeric: true, sensitivity: 'base' });
        // });
  
        // const parseLShapeSection = (section) => {
        //   const matches = section.match(/L(\d+)[\s×x]*(\d+)?[\s×x]*(\d+\/\d+)?/);
        //   if (!matches) return [0, 0, 0];
        //   const primary = parseFloat(matches[1]) || 0;
        //   const secondary = parseFloat(matches[2]) || 0;
        //   const fractional = matches[3] ? eval(matches[3].replace("⁄", "/")) : 0;
        //   return [primary, secondary, fractional];
        // };

        const parseLShapeSection = (section) => {
          const matches = section.match(/L(\d+)[\s×x]*(\d+)?[\s×x]*(\d+\/\d+)?/);
          if (!matches) return [0, 0, 0];
        
          const primary = parseFloat(matches[1]) || 0;
          const secondary = parseFloat(matches[2]) || 0;
          let fractional = 0;
        
          if (matches[3]) {
            const [numerator, denominator] = matches[3].split("⁄").map(Number);
            fractional = numerator / denominator;
          }
        
          return [primary, secondary, fractional];
        };
  
        const sortBySection = (data, category) => {
          if (category === `"L" Shapes`) {
            return data.sort((a, b) => {
              const [aPrimary, aSecondary, aFractional] = parseLShapeSection(a.section);
              const [bPrimary, bSecondary, bFractional] = parseLShapeSection(b.section);
  
              if (aPrimary !== bPrimary) return bPrimary - aPrimary;
              if (aSecondary !== bSecondary) return bSecondary - aSecondary;
              return bFractional - aFractional;
            });
          }
  
          return data.sort((a, b) =>
            b.section.localeCompare(a.section, undefined, { numeric: true, sensitivity: "base" })
          );
        };
  
        const categories = [
          "Wide Flange Sections",
          `"C" Shapes`,
          `"MC" Shapes`,
          `"L" Shapes`,
          `"S" Shapes`,
          `"HP" Shapes`,
        ];
  
        const sortedTableData = categories.reduce((acc, category) => {
          const categoryData = newTableData.filter((item) => item.category === category);
          return [...acc, ...sortBySection(categoryData, category)];
        }, []);
  
         console.log(sortedTableData);

        setIsLoadingTableData(false);
        setTableData(sortedTableData);
      } 
    };

    fetchTableData();
  }, []);

  const [selectedCategory, setSelectedCategory] = useState(
    "Wide Flange Sections"
  );
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);

  const handleCategoryChange = (e) => {
    const selectedCat = e.target.value;
    setSelectedCategory(selectedCat);
    setSelectedSubcategories([]);
  };
  const handleSubcategoryChange = (selectedList) => {
    setSelectedSubcategories(selectedList.map((item) => item.value));
  };
  const selectedCategoryObj = categories.find(
    (cat) => cat.name === selectedCategory
  );

  const categoryPlaceHolder =
    selectedSubcategories.length > 0 ? "" : "Select Category";
  const subcategoriesPlaceHolder =
    selectedSubcategories.length > 0 ? "" : "Select Sub Category";

  // Memoize filtered data
  const filteredData = React.useMemo(() => {
    if (selectedCategory === "") return tableData;
    return tableData.filter((item) => {
      if (item.category !== selectedCategory) return false; 
      if (selectedSubcategories.length === 0) return true; 
      return selectedSubcategories.includes(item.subcategory); 
    });
  }, [tableData, selectedCategory, selectedSubcategories]);

  
  if (
   isLoadingTableData
  ) {
    return (
      <div className="loading-animation1">
        <span className="loader"></span>
      </div>
    );
  }
 
  return (
    <section className="fl-tablesec">
      <div className="container-fluid px-5">
        <h3>{heading}</h3>
        <div className="main-tablearea">
          <div className="search-filter">
            <select
              name="category"
              id="category"
              className="category-select"
              value={selectedCategory}
              onChange={handleCategoryChange}
              placeholder={categoryPlaceHolder}
            >

              <option value="">{categoryPlaceHolder}</option>
              {categories.map((category) => (
                <option key={category.id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </select>
            {(selectedCategory === "" ||
              (selectedCategoryObj &&
                selectedCategoryObj.subcategories &&
                selectedCategoryObj.subcategories.length > 0)) && (
              <Multiselect
                options={
                  categories
                    .find((cat) => cat.name === selectedCategory)
                    ?.subcategories.map((subcategory) => ({
                      label: subcategory,
                      value: subcategory,
                    })) || []
                }
                selectedValues={selectedSubcategories.map((sub) => ({
                  label: sub,
                  value: sub,
                }))}
                onSelect={handleSubcategoryChange}
                onRemove={handleSubcategoryChange}
                avoidHighlightFirstOption={true}
                displayValue="label"
                placeholder={subcategoriesPlaceHolder}
              />
            )}
          </div>
          {selectedCategory === "" ? (
          <div className="no-category-sel "><p><span>*</span>Please Select a Category to Populate the Relevent Data</p></div>
        ) : (
          <div className="mn-table">
            <div className="table-responsive">
              <table>
                {categoryHeaders[selectedCategory]}

                <tbody>
                  {filteredData.map((item) => (
                    <tr key={item.section}>
                     {item.category == `Wide Flange Sections` && (
                          <>
                            <td>{item.section}</td>
                            <td>{item.weight}</td>
                            <td>{item.a}</td>
                            <td>{item.d}</td>
                            <td>{item.bf}</td>
                            <td>{item.tw}</td>
                            <td>{item.tf}</td>
                            {item.availability.A992 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A992 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                             {item.availability.A913_50 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A913_50 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                             {item.availability.A913_70 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A913_70 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                             {item.availability.A913_80 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A913_80 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                             {item.availability.A709 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A709 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                             {item.availability.A588 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A588 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                          </>
                        )}
                      
                      {item.category == `"C" Shapes` && (
                          <>
                            <td>{item.section}</td>
                            <td>{item.weight}</td>
                            <td>{item.a}</td>
                            <td>{item.d}</td>
                            <td>{item.bf}</td>
                            <td>{item.tw}</td>
                            <td>{item.tf}</td>
                            {item.availability.A992 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A992 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                          </>
                        )}

                       {item.category == `"MC" Shapes` && (
                          <>
                            <td>{item.section}</td>
                            <td>{item.weight}</td>
                            <td>{item.a}</td>
                            <td>{item.d}</td>
                            <td>{item.bf}</td>
                            <td>{item.tw}</td>
                            <td>{item.tf}</td>
                            {item.availability.A992 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A992 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                             {item.availability.A709 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A709 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                             {item.availability.A572_60 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A572_60 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                          </>
                        )}
                         {item.category == `"L" Shapes` && (
                          <>
                            <td>{item.section}</td>
                            <td>{item.weight}</td>
                            <td>{item.a}</td>
                            {item.availability.A992 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A992 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                             {item.availability.A709 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A709 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                             {item.availability.A572_50 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A572_50 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                             {item.availability.A572_65 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A572_65 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                             {item.availability.A588 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A588 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                          </>
                        )} 
                        {item.category == `"S" Shapes` && (
                          <>
                            <td>{item.section}</td>
                            <td>{item.weight}</td>
                            <td>{item.a}</td>
                            <td>{item.d}</td>
                            <td>{item.bf}</td>
                            <td>{item.tw}</td>
                            <td>{item.tf}</td>
                            {item.availability.A992 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A992 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                             {item.availability.A709 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A709 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                          </>
                        )}
                         {item.category == `"HP" Shapes` && (
                          <>
                            <td>{item.section}</td>
                            <td>{item.weight}</td>
                            <td>{item.a}</td>
                            <td>{item.d}</td>
                            <td>{item.bf}</td>
                            <td>{item.tw}</td>
                            <td>{item.tf}</td>
                            {item.availability.A992 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A992 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                             {item.availability.A913_50 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A913_50 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                             {item.availability.A913_70 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A913_70 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                             {item.availability.A913_80 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A913_80 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                             {item.availability.A709 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A709 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                             {item.availability.A588 === true ? (
                              <td>
                                <img src={check} alt="ArcelorMittal International" />
                              </td>
                            ) : item.availability.A588 === false ? (
                              <td>
                                <img src={error} alt="ArcelorMittal International" />
                              </td>
                            ) : (
                              <td></td>
                            )}
                          </>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
           )}
        </div>
        <p>Click here for a printable version of our shape availability chart <a href={pdfLink} target='_blank'  rel="noreferrer" download className="txtbtn">{pdfName}</a></p>
      </div>
    </section>
  );
};

export default PRTable;

