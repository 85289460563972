import React, { useState, useEffect } from "react";
import SiteHeader from "../../Header/SiteHeader";
import SubpageBanner from "../../Banner/SubpageBanner";
import Info from "../../Info/Info";
import Footer from "../../Footer/Footer";
import { ApiFlatsSustain, ApiFlats, ApiFilterHome } from "../../API/API";
import MetaData from "../../MetadataComp/MetaData";

const FlatSustainability = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [title] = useState("Sustainability: Flats - ArcelorMittal International North America");
  const [description] = useState("Sustainability, XCarb, Environmental Product Declaration (EPD) by ArcelorMittal International North America");
  const [keywords] = useState("Sustainability, XCarb, Environmental Product Declaration (EPD)");

  const [isLoadingBannerData, setIsLoadingBannerData] = useState(true);
  const [bannerData, setBannerData] = useState([
    {
      id: null,
      title: null,
    },
  ]);

  useEffect(() => {
    ApiFlats()
      .then((response) => {
        if (response.data.data.attributes.sustainaibility) {
          const item = response.data.data.attributes.sustainaibility;

          const newBannerData = {
            id: item.id,
            title: item.sustainaibility_heading,
          };

          setBannerData(newBannerData);
          setIsLoadingBannerData(false);
        }
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  const [isLoadingBannerBreadcrumb, setIsLoadingBannerBreadcrumb] = useState(true);
  const [bannerBreadcrumb, setBannerBreadcrumb] = useState([
    {
      id: null,
      pageLink: null,
      linkTitle: null
    },
  ]);
  
  useEffect(() => {
    ApiFilterHome("/flats")
      .then((response) => {
        if (response.data.data.attributes.businessline_block_item.length>0) {
          const item = response.data.data.attributes.businessline_block_item[0] ;
  
          const newBannerBreadcrumb = {
            id: item.id,
            pageLink: item.url,
            linkTitle: item.title_text
          };
  
          setBannerBreadcrumb(newBannerBreadcrumb);
          setIsLoadingBannerBreadcrumb(false);
        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[]);

  //API fetch for sustainability block (info1)
  const [isLoadingInfoData1, setIsLoadingInfoData1] = useState(true);
  const [infoData1, setinfoData1] = useState([
    {
      id: 0,
      infoTitle: null,
      infoImage: null,
      infoPara: null,
      infoAlt: null
    },
  ]);

  useEffect(() => {
    ApiFlatsSustain(apiUrl)
      .then((response) => {
        if (response.data.data.attributes.sustainaibility_details) {
          const item = response.data.data.attributes.sustainaibility_details;

          const newInfoData1 = {
            id: item.id,
            infoTitle: item.title,
            infoPara: item.Description,
            infoImage: `${item.image.data.attributes.url}`,
            infoAlt: item.img_alt_txt
          };
          setinfoData1(newInfoData1);
          setIsLoadingInfoData1(false);
        }
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  //API fetch for XCarb block (info2)
  const [isLoadingInfoData2, setIsLoadingInfoData2] = useState(true);
  const [infoData2, setinfoData2] = useState([
    {
      id: 0,
      infoTitle: null,
      infoImage: null,
      infoPara: null,
      infoAlt: null,
      isDownloadButtonEnabled: null,
      downloadButtonLabel: null,
      fileLink: null,
    },
  ]);

  useEffect(() => {
    ApiFlatsSustain(apiUrl)
      .then((response) => {
        if (response.data.data.attributes.xcarbs_details_1) {
          const item = response.data.data.attributes.xcarbs_details_1;

          const fileLinks = item.pdf_items.map((pdfItem) => ({
            title: 'XCarb Brochure.pdf',
            url: `${pdfItem.pdf.data.attributes.url}`,
          }));

          const newInfoData2 = {
            id: item.id,
            infoTitle: item.title,
            infoPara: item.description,
            infoImage: `${item.image.data.attributes.url}`,
            infoAlt: item.img_alt_txt,
            isDownloadButtonEnabled: fileLinks.length > 0,
            downloadButtonLabel: fileLinks.map((link) => link.title),
            fileLinks: fileLinks,
          };
          setinfoData2(newInfoData2);
          setIsLoadingInfoData2(false);
        }
        //  else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);


  const [isLoadingInfoData3, setIsLoadingInfoData3] = useState(true);
  const [infoData3, setinfoData3] = useState({
    id: 0,
    infoTitle: null,
    infoImage: null,
    infoPara: null,
    infoAlt: null,
    isDropdownEnabled: false,
    dropdownLabel: null,
    files: [],
  });

  useEffect(() => {
    ApiFlatsSustain(apiUrl)
      .then((response) => {
        if (response.data.data.attributes.xcarbs_details_2) {
          const item = response.data.data.attributes.xcarbs_details_2;

          const files = item.pdf_items.map((pdfItem) => ({
            name: pdfItem.title,
            path: `${pdfItem.pdf.data.attributes.url}`,
          }));

          const newInfoData3 = {
            id: item.id,
            infoTitle: item.title,
            infoPara: item.description,
            infoImage: `${item.image.data.attributes.url}`,
            infoAlt: item.img_alt_txt,
            isDropdownEnabled: files.length > 0,
            dropdownLabel: "Download PDF",
            files: files,
          };
          setinfoData3(newInfoData3);
          setIsLoadingInfoData3(false);
        }
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  if (
    isLoadingBannerData ||
    isLoadingBannerBreadcrumb ||
    isLoadingInfoData1 ||
    isLoadingInfoData2 ||
    isLoadingInfoData3
  ) {
    return (
      <div className="loading-animation">
        <span className="loader"></span>
      </div>
    );
  }

  return (
    <>
    <MetaData 
        title={title}
        description={description}
        keywords={keywords}
      />
      <SiteHeader />
      <SubpageBanner {...bannerData} {...bannerBreadcrumb} />
      <section className="sections-common-sec sustainabilitysec">
        <Info {...infoData1} />
        <Info {...infoData2} />
        <Info {...infoData3} />
      </section>
      <Footer />
    </>
  );
};

export default FlatSustainability;
