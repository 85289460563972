import React, { useState, useEffect } from "react";
import plus from "../../assets/images/plus.png";
import cross from "../../assets/images/minus.svg";
import twitterIcon from "../../assets/images/twitter.png";
import linkedinIcon from "../../assets/images/linkedin.png";
import facebookIcon from "../../assets/images/facebook.png";
import instagramIcon from "../../assets/images/instagram.png";
import youtubeIcon from "../../assets/images/youtube.png";
import spotifyIcon from "../../assets/images/spotify.png";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/custom-style.css";
import "../../assets/css/variable.css";
import { Link } from "react-router-dom";
import {
  ApiSocialMedia,
  ApiStrucShape,
  ApiBarsRods,
  ApiWireSol,
  ApiFlats,
} from "../API/API";

const Footer = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isMobile, setIsMobile] = useState(false);
  const [smLink, setSMLink] = useState([
    {
      id: 0,
      name: null,
      icon: null,
      link: null,
    },
  ]);

  const ensureHttps = (url) => {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return `https://${url}`;
    }
    return url;
  };
  useEffect(() => {
    ApiSocialMedia()
      .then((response) => {
        if (response.data.data.attributes.social_item) {
          const data = response.data.data.attributes.social_item;
          const newSMLink = data.map((item) => {
            return {
              id: item.id,
              name: item.title_text,
              icon: item.img_icon,
              link:
                item.url !== null && item.url !== ""
                  ? ensureHttps(item.url)
                  : item.url,
            };
          });
          setSMLink(newSMLink);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  const handleToggleClick = (event) => {
    const parent = event.target.closest(".toggle-heading");
    if (parent) {
      const nestedList = parent.nextElementSibling;
      if (nestedList) {
        nestedList.classList.toggle("active");
      }
      parent.classList.toggle("caret-down");
    }
  };

  const ScrollToItemClick = (sectionId) => {
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        const offsetTop = section.getBoundingClientRect().top;
        const navbarHeight = document.querySelector(".navbar").offsetHeight;
        const scrollPosition = window.scrollY + offsetTop - navbarHeight;
        window.scrollTo({ top: scrollPosition, behavior: "smooth" });
      }
    }, 100);
  };

  const [isLoadingStrucShapeLinks, setIsLoadingStrucShapeLinks] = useState(true);
  const [strucShapeLinks, setStrucShapeLinks] = useState([
    {
      id: 0,
      label: null,
      to: null,
    },
  ]);

  useEffect(() => {
    ApiStrucShape(apiUrl)
      .then((response) => {
        if (response.data.data.attributes.businessline_block_item) {
          const data_bl = response.data.data.attributes.businessline_block_item;
          data_bl.sort((a, b) => a.order_no - b.order_no);
          const newStrucShapeLinks = data_bl.map((item) => {
            return {
              id: item.order_no,
              label: item.title_text,
              to: item.url,
            };
          });
          setIsLoadingStrucShapeLinks(false);
          setStrucShapeLinks(newStrucShapeLinks);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  const [isLoadingFlatsLinks, setIsLoadingFlatsLinks] = useState(true);
  const [flatsLinks, setFlatsLinks] = useState([
    {
      id: 0,
      label: null,
      to: null,
    },
  ]);

  useEffect(() => {
    ApiFlats(apiUrl)
      .then((response) => {
        if (response.data.data.attributes.businessline_block_item) {
          const data_bl = response.data.data.attributes.businessline_block_item;
          const baseUrl = window.location.origin;
          data_bl.sort((a, b) => a.order_no - b.order_no);
          const newFlatsLinks = data_bl.map((item) => {
            return {
              id: item.order_no,
              label: item.title_text,
              to: `${baseUrl}/flats/${item.url}`,
            };
          });
          setIsLoadingFlatsLinks(false);
          setFlatsLinks(newFlatsLinks);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);
  const [sustainTo, setSustainTo] = useState(null);
  const [sustainLabel, setSustainLabel] = useState(null);
  useEffect(() => {
    ApiFlats(apiUrl)
      .then((response) => {
        if (response.data.data.attributes.sustainaibility) {
          const item = response.data.data.attributes.sustainaibility;
          setSustainLabel(item.sustainaibility_heading);
          setSustainTo("/flats/sustainability");
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);
  
  const [isLoadingWireSolLinks, setIsLoadingWireSolLinks] = useState(true);
  const [wireSolLinks, setWireSolLinks] = useState([
    {
      id: 0,
      label: null,
      to: null,
    },
  ]);

  useEffect(() => {
    ApiWireSol(apiUrl)
      .then((response) => {
        if (response.data.data.attributes.businessline_block_item) {
          const data_bl = response.data.data.attributes.businessline_block_item;
          data_bl.sort((a, b) => a.order_no - b.order_no);
          const newWireSolLinks = data_bl.map((item) => {
            return {
              id: item.order_no,
              label: item.title_text,
              to: item.url,
            };
          });
          setIsLoadingWireSolLinks(false);
          setWireSolLinks(newWireSolLinks);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);
 
  const [isLoadingBarsRodsLinks, setIsLoadingBarsRodsLinks] = useState(true); 
  const [barsRodsLinks, setBarsRodsLinks] = useState([
    {
      id: 0,
      label: null,
      to: null,
      sec_id: null
    },
  ]);

  useEffect(() => {
    ApiBarsRods(apiUrl)
      .then((response) => {
        if (response.data.data.attributes.businessline_block_item) {
          const data_bl = response.data.data.attributes.businessline_block_item;
          const baseUrl = window.location.origin;
          data_bl.sort((a, b) => a.order_no - b.order_no);
          const newBarsRodsLinks = data_bl.map((item) => {
            return {
              id: item.order_no,
              label: item.title_text,
              to: `${baseUrl}/bars-and-rods#${item.sec_id}`,
              sec_id: item.sec_id,
            };
          });
          setIsLoadingBarsRodsLinks(false)
          setBarsRodsLinks(newBarsRodsLinks);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);
  
  if (
    isLoadingStrucShapeLinks || isLoadingBarsRodsLinks || isLoadingFlatsLinks || isLoadingWireSolLinks
  ) 
  {
    return (
      <div className="loading-animation1">
        <span className="loader"></span>
      </div>
    );
  }

  return (
    <>
      <footer className="ft-sec">
        <div className="upr-mn-ft">
          <div className="container-fluid px-5">
            <div className="upr-ftr">
              <div className="row">
                <div className="col-md-2">
                  <div className="sn-ftr-ql">
                    <div className="toggle-heading" onClick={handleToggleClick}>
                      <h3>Structural Shapes</h3>
                      <div className="caret1">
                        <img className="open" src={plus} alt="ArcelorMittal International" />
                        <img className="close" src={cross} alt="ArcelorMittal International" />
                      </div>
                    </div>
                    <ul className="nested">
                      {strucShapeLinks.map((link, index) => (
                        <li key={index}>
                          <Link to={link.to}>{link.label}</Link>
                        </li>
                      ))}
                      <li>
                        <Link
                          to="/structural-shapes#contact"
                          onClick={() => ScrollToItemClick("contact")}
                        >
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="sn-ftr-ql">
                    <div className="toggle-heading" onClick={handleToggleClick}>
                      <h3>Bars and Rods</h3>
                      <div className="caret1">
                        <img className="open" src={plus} alt="ArcelorMittal International" />
                        <img className="close" src={cross} alt="ArcelorMittal International" />
                      </div>
                    </div>
                    <ul className="nested">
                      {/* <li>
                        <Link
                          to="/bars-and-rods#sp_bar"
                          onClick={() => ScrollToItemClick("sp_bar")}
                        >
                          Special Bar Quality
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/bars-and-rods#wire_coil"
                          onClick={() => ScrollToItemClick("wire_coil")}
                        >
                          Wire Rod in Coil
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/bars-and-rods#semis_sec"
                          onClick={() => ScrollToItemClick("semis_sec")}
                        >
                          Semis
                        </Link>
                      </li> */}
                         {barsRodsLinks.map((link, index) => (
        <li key={index}>
          <Link
            to={link.to}
            onClick={() => ScrollToItemClick(link.sec_id)}
          >
            {link.label}
          </Link>
        </li>
      ))}
                      <li>
                        <Link
                          to="/bars-and-rods#contact"
                          onClick={() => ScrollToItemClick("contact")}
                        >
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="sn-ftr-ql">
                    <div className="toggle-heading" onClick={handleToggleClick}>
                      <h3>Flats</h3>
                      <div className="caret1">
                        <img className="open" src={plus} alt="ArcelorMittal International" />
                        <img className="close" src={cross} alt="ArcelorMittal International" />
                      </div>
                    </div>
                    <ul className="nested">
                      {flatsLinks.map((link, index) => (
                        <li key={index}>
                          <Link to={link.to}>{link.label}</Link>
                        </li>
                      ))}
                      <li>
                        <Link to={sustainTo}>{sustainLabel}</Link>
                      </li>
                      <li>
                        <Link
                          to="/flats#contact"
                          onClick={() => ScrollToItemClick("contact")}
                        >
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="sn-ftr-ql">
                    <div className="toggle-heading" onClick={handleToggleClick}>
                      <h3>Logistics</h3>
                      <div className="caret1">
                        <img className="open" src={plus} alt="ArcelorMittal International" />
                        <img className="close" src={cross} alt="ArcelorMittal International" />
                      </div>
                    </div>
                    <ul className="nested">
                      <li>
                        <Link
                          to="/logistics#mapsec"
                          onClick={() => ScrollToItemClick("mapsec")}
                        >
                          Ports we ship to
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/logistics#log-gal"
                          onClick={() => ScrollToItemClick("log-gal")}
                        >
                          Transportation Modes Gallery
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/logistics#contact"
                          onClick={() => ScrollToItemClick("contact")}
                        >
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="sn-ftr-ql">
                    <div className="toggle-heading" onClick={handleToggleClick}>
                      <h3>Wire Solutions</h3>
                      <div className="caret1">
                        <img className="open" src={plus} alt="ArcelorMittal International" />
                        <img className="close" src={cross} alt="ArcelorMittal International" />
                      </div>
                    </div>
                    <ul className="nested">
                      {/* <li>
                        <Link to="/wire-solutions/steel-fibres">
                          Steel Fibres
                        </Link>
                      </li>
                      <li>
                        <Link to="/wire-solutions/fencing-vineyard-wire">
                          Fencing and Vineyard Wire
                        </Link>
                      </li>
                      <li>
                        <Link to="/wire-solutions/wire-ropes">Wire Ropes</Link>
                      </li> */}
                       {wireSolLinks.map((link, index) => (
        <li key={index}>
          <Link to={link.to}>
            {link.label}
          </Link>
        </li>
      ))}
                      <li>
                        <Link
                          to="/wire-solutions#contact"
                          onClick={() => ScrollToItemClick("contact")}
                        >
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-2">
                  {smLink.filter((social) => social.link).length > 0 && (
                    <div className="sn-ftr-ql foot-social">
                      <div
                        className="toggle-heading"
                        onClick={handleToggleClick}
                      >
                        <h3>Social</h3>
                        <div className="caret1">
                          <img className="open" src={plus} alt="ArcelorMittal International" />
                          <img className="close" src={cross} alt="ArcelorMittal International" />
                        </div>
                      </div>
                      <ul className="nested">
                        {smLink
                          .filter((social) => social.link) // Filter out entries without a link
                          .map((social, index) => {
                            const formatName = social.name.trim().toLowerCase();
                            if (formatName === "facebook") {
                              return (
                                <li key={index}>
                                  <Link to={social.link} target="_blank">
                                    <img src={facebookIcon} alt={social.name} />
                                    {social.name}
                                  </Link>
                                </li>
                              );
                            } else if (formatName === "twitter") {
                              return (
                                <li key={index}>
                                  <Link to={social.link} target="_blank">
                                    <img src={twitterIcon} alt={social.name} />
                                    {social.name}
                                  </Link>
                                </li>
                              );
                            } else if (formatName === "linkedin") {
                              return (
                                <li key={index}>
                                  <Link to={social.link} target="_blank">
                                    <img src={linkedinIcon} alt={social.name} />
                                    {social.name}
                                  </Link>
                                </li>
                              );
                            } else if (formatName === "instagram") {
                              return (
                                <li key={index}>
                                  <Link to={social.link} target="_blank">
                                    <img
                                      src={instagramIcon}
                                      alt={social.name}
                                    />
                                    {social.name}
                                  </Link>
                                </li>
                              );
                            } else if (formatName === "youtube") {
                              return (
                                <li key={index}>
                                  <Link to={social.link} target="_blank">
                                    <img src={youtubeIcon} alt={social.name} />
                                    {social.name}
                                  </Link>
                                </li>
                              );
                            } else if (formatName === "spotify") {
                              return (
                                <li key={index}>
                                  <Link to={social.link} target="_blank">
                                    <img src={spotifyIcon} alt={social.name} />
                                    {social.name}
                                  </Link>
                                </li>
                              );
                            }
                            return null;
                          })}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="container-fluid px-5">
            <div className="row">
              <div className="col-md-12">
                <div className="copyright-area">
                  <p>
                    &copy; Copyright 2024 <Link to="/#">Arcelormittal</Link>.
                    All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
