import React, { useEffect, useState } from "react";
import SubpageBanner from "../../Banner/SubpageBanner";
import SiteHeader from "../../Header/SiteHeader";
import SubpageInfo from "../../Info/SubpageInfo";
import Footer from "../../Footer/Footer";
import { ApiAgri, ApiFilterFlat, ApiFilterHome } from "../../API/API";
import MetaData from "../../MetadataComp/MetaData";

const Agriculture = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [title] = useState("Agriculture - ArcelorMittal International North America");
  const [description] = useState("ArcelorMittal Products for Agriculture sector");
  const [keywords] = useState("Agriculture, Silos, buildings, greenhouses, fences, HVAC, tillage and hardware, mower blades");
  
  const [isLoadingBannerData, setIsLoadingBannerData] = useState(true);
  const [bannerData, setBannerData] = useState([
    {
      id: null,
      title: null,
    },
  ]);


  //Api fetch for infoData
  const [isLoadingInfoData, setIsLoadingInfoData] = useState(true);
  const [infoData, setinfoData] = useState([
    {
      id: 0,
      infoTitle: null,
      infoImage: null,
      infoPara: null,
      infoUrl: null,
      infoAlt: null,
    },
  ]);

  useEffect(() => {
    ApiAgri(apiUrl)
      .then((response) => {
        if (
          response.data.data.attributes.agriculture_details) {
          const item = response.data.data.attributes.agriculture_details;

          const newInfoData = {
            id: item.id,
            infoTitle: item.title,
            infoPara: item.description,
            infoImage: `${item.image.data.attributes.url}`,
            infoUrl: item.page_url,
            infoAlt: item.img_alt_txt
          };

          setinfoData(newInfoData);
          setIsLoadingInfoData(false);
          ApiFilterFlat(item.page_url)
          .then((response) => {
            if (response.data.data.attributes.businessline_block_item.length>0) {
              const item = response.data.data.attributes.businessline_block_item[0];
    
              const newBannerData = {
                id: item.id,
                title: item.title_text,
              };
    
              setBannerData(newBannerData);
              setIsLoadingBannerData(false);
            } 
            // else {
            //   console.log(response.data);
            // }
          })
          .catch((error) => {
            console.error("Error fetching data: ", error);
          });
        }
        //  else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[apiUrl]);
  
  const [isLoadingBannerBreadcrumb, setIsLoadingBannerBreadcrumb] = useState(true);
  const [bannerBreadcrumb, setBannerBreadcrumb] = useState([
    {
      id: null,
      pageLink: null,
      linkTitle: null
    },
  ]);
  
  useEffect(() => {
    ApiFilterHome("/flats")
      .then((response) => {
        if (response.data.data.attributes.businessline_block_item.length>0) {
          const item = response.data.data.attributes.businessline_block_item[0] ;
  
          const newBannerBreadcrumb = {
            id: item.id,
            pageLink: item.url,
            linkTitle: item.title_text
          };
  
          setBannerBreadcrumb(newBannerBreadcrumb);
          setIsLoadingBannerBreadcrumb(false);
        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[]);

  if (isLoadingBannerData || isLoadingBannerBreadcrumb|| isLoadingInfoData) {
    return (
      <div className="loading-animation">
       <span className="loader"></span>
      </div>
    );
  }

  return (
    <>
      <MetaData 
        title={title}
        description={description}
        keywords={keywords}
      />
      <SiteHeader />
      <SubpageBanner {...bannerData} {...bannerBreadcrumb} />
      <SubpageInfo {...infoData} />
      <Footer />
    </>
  );
};

export default Agriculture;
