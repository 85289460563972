import React from "react";
import home from '../../assets/images/home-ic.svg';
import { Link, useLocation } from "react-router-dom";
const SubpageBanner = ({title, pageLink, linkTitle}) => {

  const location = useLocation();
  const shouldRenderLink = location.pathname !== '/latest-news';
  return (
    <section className="brdcrm-banner-section">
    <div className="bread-crmsec">
      <div className="container-fluid px-5">
        <div className="main-brdcrmarea">
          <ul>
            <li>
              <Link to="/" className="hm-link">
                <img src={home} alt="Home Icon" />Home
              </Link>
            </li>
           {shouldRenderLink && (
                <li>
                  <Link to={pageLink} className="hm-link">{linkTitle}</Link>
                </li>
              )}
          </ul>
          <h2>{title}</h2>
        </div>
      </div>
    </div>
  </section>
  );
};
export default SubpageBanner;
