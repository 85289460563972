import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import GallCarousel from "./GallCarousel";
import parse from "html-react-parser";

const ProjGalleryAccordianInfo = ({heading , galItems }) => {
  const [activeIndex, setActiveIndex] = useState("0"); // Initially set to "0" to open the first item
  const [carouselKey, setCarouselKey] = useState(0); // Key to reset the carousel

  const handleAccordionSelect = (index) => {
    // Toggle active index for accordion items
    setActiveIndex(index === activeIndex ? null : index);
    setCarouselKey((prevKey) => prevKey + 1); // Increment key to reset the carousel
  };

  return (
    <section className="st-gallsec">
      <div className="container-fluid px-5">
        <h3 className="text-center">
        {heading}
        </h3>
        <div className="st-accodian-sec">
          <div className="col-md-12">
            <Accordion activeKey={activeIndex} onSelect={handleAccordionSelect}>
              {galItems.map((item, index) => (
                <Accordion.Item key={index.toString()} eventKey={index.toString()}>
                  <Accordion.Header>{item.header}</Accordion.Header>
                  <Accordion.Body>
                    <div className="gall-rowsec">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="main-info">
                            <div className="info-text subtext">
                              {item.description && parse(item.description)}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <GallCarousel images={item.images} imgAlt={item.imgAlt} key={carouselKey} />
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjGalleryAccordianInfo;
