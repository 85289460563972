import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Banner from "../Banner/Banner";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/custom-style.css";
import "../../assets/css/variable.css";
import BuisnessLine from "../BuisnessLine/BuisnessLine";
import LatestNews from "../LatestNews/LatestNews";
import Newsletter from "../Newsletter/Newsletter";
import { ApiHome, ApiHomeLatestNews, ApiLatestNews } from "../API/API";
import SiteHeader from "../Header/SiteHeader";
import MetaData from "../MetadataComp/MetaData"
const HomePage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  
  // Api fetch for Banner
  const [isLoadingBanner, setIsLoadingBanner] = useState(true);
  const [bannerData, setBannerData] = useState([
    {
      id: 0,
      image: null,
      alt: null,
      caption: null,
      indicatorText: null,
    },
  ]);
  

  useEffect(() => {
    ApiHome(apiUrl)
      .then((response) => {
        if (
          response.data.data.attributes.banner_section
        ) {
          const data = response.data.data.attributes.banner_section;
          data.sort((a, b) => a.order_no - b.order_no);
          const newBannerData = data.map((item) => {
            return {
              id: item.order_no,
              image: `${item.main_img.data.attributes.url}`,
              alt: item.img_alt_title,
              caption: item.title,
              indicatorText: item.navigator_text,
            };
          });

          setBannerData(newBannerData);
          setIsLoadingBanner(false);
        } 
      })
      .catch((error) => {
        console.error("Error fetching newBannerData: ", error.message);
      });
  },[apiUrl]);
   

  //Api fetch for BuisnessLine
  const [isLoadingBLine, setIsLoadingBLine] = useState(true);
  const [bLineData, setBLineData] = useState([
    {
      id: 0,
      name: null,
      image: null,
      route: "",
      alt: null,
    },
  ]);

  const [datanewstest, Setdatanewstest] = useState([]);

  useEffect(() => {
    ApiHome(apiUrl)
      .then((response) => {
        if (
          response.data.data.attributes.businessline_block_item
        ) {
          const data_bl = response.data.data.attributes.businessline_block_item;
          data_bl.sort((a, b) => a.order_no - b.order_no);
          const newBLineData = data_bl.map((item) => {
            return {
              id: item.order_no,
              name: item.title_text,
              route: item.url,
              image: `${item.bg_img.data.attributes.url}`,
              alt: item.img_alt_title
            };
          });
          setIsLoadingBLine(false);
          setBLineData(newBLineData);
        }
        
      })
      .catch((error) => {
        console.error("Error fetching newBLineData: ", error.message);
      });
      

      ApiHomeLatestNews()
      .then((response) => {
        if (response) {
          const item = response.data.data.attributes;
          setNewsTitle(item.latest_blogs_title);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error.message);
      });


      ApiLatestNews(apiUrl)
      .then((response1) => {
        if (response1.data.data) {
          (response1.data.data).sort((a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt));
          const data = (response1.data.data).slice(0, 3);
          Setdatanewstest(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching ApiLatestNews: ", error.message);
      });
  },[apiUrl]);


  const sliderProps = {
    toShow: 5,
    toScroll: 1,
  };

  //Api fetch for newsLetter
  const [isLoadingNewsLetter, setIsLoadingNewsLetter] = useState(true);
  const [newsLetter, setNewsLetter] = useState([
    {
      id: 0,
      title: null,
      para: null,
      buttonLabel: null,
    },
  ]);

  useEffect(() => {
    ApiHome()
      .then((response) => {
        if (response.data.data.attributes.newsletter_section) {
          const item = response.data.data.attributes.newsletter_section;

          const newNewsLetter = {
            id: item.id,
            title: item.news_head,
            para: item.news_desc,
            buttonLabel: 'Subscribe'
          };
          setNewsLetter(newNewsLetter);
          setIsLoadingNewsLetter(false);
        } 
      })
      .catch((error) => {
        console.error("Error fetching ApiHomedata: ", error.message);
      });
  },[]);
   
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [keywords, setKeyword] = useState(null);
  useEffect(() => {
    ApiHome()
      .then((response) => {
        if (response.data.data.attributes.seo) {
          const item = response.data.data.attributes.seo;
          setTitle(item.page_title);
          setDescription(item.meta_description);
          setKeyword(item.meta_keywords);
        }        
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  });


  // const [showLatestNews, setShowLatestNews] = useState(null);

  // useEffect(() => {
  //   ApiHomeLatestNews()
  //     .then((response) => {
  //       if (response.data.data.attributes) {
  //         const item = response.data.data.attributes;
  //         setShowLatestNews(item.show_homepage_blog_section);
  //       } 
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching ApiHomeLatestNewsdata: ", error.message);
  //     });
  // },[]);


  const [newsTitle, setNewsTitle] = useState("");

  if (isLoadingBanner || isLoadingBLine || isLoadingNewsLetter  ) {
    return (
      <div className="loading-animation">
       <span className="loader"></span>
      </div>
    );
  }
  
  return (
    <>
    <MetaData 
        title={title}
        description={description}
        keywords={keywords}
      />
      <SiteHeader />
      <Banner bannerData={bannerData} />
      <BuisnessLine
        bLineData={bLineData}
        {...sliderProps}
        className="home-bline"
      />
      <LatestNews newsTitle={newsTitle} newsData={datanewstest}/>
      <Newsletter {...newsLetter} />
      <Footer />
    </>
  );
};

export default HomePage;
