import React, { useState, useEffect } from "react";
import SiteHeader from "../../Header/SiteHeader";
import SubpageBanner from "../../Banner/SubpageBanner";
import SubpageInfo from "../../Info/SubpageInfo";
import Footer from "../../Footer/Footer";
import { ApiTrans, ApiFilterFlat, ApiFilterHome } from "../../API/API";
import MetaData from "../../MetadataComp/MetaData";

const Transportation = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [title] = useState("Transportation - ArcelorMittal International North America");
  const [description] = useState("ArcelorMittal's offer for the transport sector includes a range of lightweight high strength steels which can reduce the financial and environmental cost of freight.");
  const [keywords] = useState("Trains (Chassis, Car walls, …) and rails (fasteners) applications, Trucks (chassis, trailers, cabins...) and roads (crash barriers).");

  const [isLoadingBannerData, setIsLoadingBannerData] = useState(true);
  const [bannerData, setBannerData] = useState([
    {
      id: null,
      title: null,
    },
  ]);

  const [isLoadingInfoData, setIsLoadingInfoData] = useState(true);
  const [infoData, setinfoData] = useState([
    {
      id: 0,
      infoTitle: null,
      infoImage: null,
      infoPara: null,
      infoUrl: null,
      infoAlt: null
    },
  ]);

  useEffect(() => {
    ApiTrans(apiUrl)
      .then((response) => {
        if (response.data.data.attributes.transportation_details) {
          const item = response.data.data.attributes.transportation_details;

          const newInfoData = {
            id: item.id,
            infoTitle: item.title,
            infoPara: item.description,
            infoImage: `${item.image.data.attributes.url}`,
            infoUrl: item.page_url,
            infoAlt: item.img_alt_txt
          };
          setinfoData(newInfoData);
          setIsLoadingInfoData(false);
          ApiFilterFlat(item.page_url)
            .then((response) => {
              if (
                response.data.data.attributes.businessline_block_item.length > 0
              ) {
                const item =
                  response.data.data.attributes.businessline_block_item[0];

                const newBannerData = {
                  id: item.id,
                  title: item.title_text,
                };

                setBannerData(newBannerData);
                setIsLoadingBannerData(false);
              }
              // else {
              //   console.log(response.data);
              // }
            })
            .catch((error) => {
              console.error("Error fetching data: ", error);
            });
        }
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [apiUrl]);

  const [isLoadingBannerBreadcrumb, setIsLoadingBannerBreadcrumb] =
    useState(true);
  const [bannerBreadcrumb, setBannerBreadcrumb] = useState([
    {
      id: null,
      pageLink: null,
      linkTitle: null,
    },
  ]);

  useEffect(() => {
    ApiFilterHome("/flats")
      .then((response) => {
        if (response.data.data.attributes.businessline_block_item.length > 0) {
          const item = response.data.data.attributes.businessline_block_item[0];

          const newBannerBreadcrumb = {
            id: item.id,
            pageLink: item.url,
            linkTitle: item.title_text,
          };

          setBannerBreadcrumb(newBannerBreadcrumb);
          setIsLoadingBannerBreadcrumb(false);
        }
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  if (isLoadingBannerData || isLoadingBannerBreadcrumb || isLoadingInfoData) {
    return (
      <div className="loading-animation">
        <span className="loader"></span>
      </div>
    );
  }

  return (
    <>
    <MetaData 
        title={title}
        description={description}
        keywords={keywords}
      />
      <SiteHeader />
      <SubpageBanner {...bannerData} {...bannerBreadcrumb} />
      <SubpageInfo {...infoData} />
      <Footer />
    </>
  );
};

export default Transportation;
