import React, {useState, useEffect} from "react";
import SiteHeader from "../../Header/SiteHeader";
import SubpageBanner from "../../Banner/SubpageBanner";
import Footer from "../../Footer/Footer";
import Info from "../../Info/Info";
import { ApiSteelFibres,ApiFilterWireSol, ApiFilterHome } from "../../API/API";
import MetaData from "../../MetadataComp/MetaData";

const SteelFibres = () => {

  const apiUrl = process.env.REACT_APP_API_URL;

  const [title] = useState("Steel Fibres - ArcelorMittal International North America");
  const [description] = useState("ArcelorMittal Fibres do much more than manufacture and supply a comprehensive range of premium quality steel fibres. We support you to ensure the success of your project from start to finish.");
  const [keywords] = useState("Steel Fibres, TABIX, Undulated Fibre, HE, Hooked-End or Crimped Fibre, FE, Flat-End Fibre, HFE, Hooked Flat-End Fibre");

  const [isLoadingBannerData, setIsLoadingBannerData] = useState(true);
  const [bannerData, setBannerData] = useState([
    {
      id: null,
      title: null,
    },
  ]);

  //API fetch for info block 1 (steel fibre block)
const [isLoadingInfoData1, setIsLoadingInfoData1] = useState(true);
const [infoData1, setinfoData1] = useState([
    {
      id: 0,
      infoTitle: null,
      infoImage: null,
      infoPara: null,
      infoAlt: null
    },
  ]);

  useEffect(() => {
    ApiSteelFibres(apiUrl)
      .then((response) => {
        if (
          response.data.data.attributes.section1_details
        ) {
          const item = response.data.data.attributes.section1_details;

          const newInfoData1 = {
            id: item.id,
            infoTitle: item.title,
            infoPara: item.description,
            infoImage: `${item.image.data.attributes.url}`,
            infoUrl: item.page_url,
            infoAlt: item.img_alt_txt
          };
          setinfoData1(newInfoData1);
          setIsLoadingInfoData1(false);
          ApiFilterWireSol(item.page_url)
          .then((response) => {
            if (response.data.data.attributes.businessline_block_item.length>0) {
              const item = response.data.data.attributes.businessline_block_item[0];
    
              const newBannerData = {
                id: item.id,
                title: item.title_text,
              };
    
              setBannerData(newBannerData);
              setIsLoadingBannerData(false);
            } 
            // else {
            //   console.log(response.data);
            // }
          })
          .catch((error) => {
            console.error("Error fetching data: ", error);
          });
        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[apiUrl]);
  
   const [isLoadingBannerBreadcrumb, setIsLoadingBannerBreadcrumb] = useState(true);
   const [bannerBreadcrumb, setBannerBreadcrumb] = useState([
    {
      id: null,
      pageLink: null,
      linkTitle: null
    },
  ]);
  
  useEffect(() => {
    ApiFilterHome("/wire-solutions")
      .then((response) => {
        if (response.data.data.attributes.businessline_block_item.length>0) {
          const item = response.data.data.attributes.businessline_block_item[0] ;
  
          const newBannerBreadcrumb = {
            id: item.id,
            pageLink: item.url,
            linkTitle: item.title_text
          };
  
          setBannerBreadcrumb(newBannerBreadcrumb);
          setIsLoadingBannerBreadcrumb(false);
        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[]);


  //API fetch for info block 2 (sustainability Brochures)

  const [isLoadingInfoData2, setIsLoadingInfoData2] = useState(true);
const [infoData2, setinfoData2] = useState([
    {
      id: 0,
      infoTitle: null,
      infoPara: null,
      infoImage: null,
      infoAlt: null
    },
  ]);

  useEffect(() => {
    ApiSteelFibres(apiUrl)
      .then((response) => {
        if (
          response.data.data.attributes.section2_details
        ) {
          const item = response.data.data.attributes.section2_details;

          const newInfoData2 = {
            id: item.id,
            infoTitle: item.title,
            infoPara: item.description,
            infoImage: `${item.image.data.attributes.url}`,
            infoAlt: item.img_alt_txt
          };
          setinfoData2(newInfoData2);
          setIsLoadingInfoData2(false);
        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[apiUrl]);
  

  //API fetch for info block 3 (fibre Brochures)

  const [isLoadingInfoData3, setIsLoadingInfoData3] = useState(true);
const [infoData3, setinfoData3] = useState([
    {
      id: 0,
      infoTitle: null,
      infoPara: null,
      infoImage: null,
      infoAlt: null,
    },
  ]);

  useEffect(() => {
    ApiSteelFibres(apiUrl)
      .then((response) => {
        if (
          response.data.data.attributes.section2_details
        ) {
          const item = response.data.data.attributes.section3_details;

          const newInfoData3 = {
            id: item.id,
            infoTitle: item.title,
            infoPara: item.description,
            infoImage: `${item.image.data.attributes.url}`,
            infoAlt: item.img_alt_txt
          };
          setinfoData3(newInfoData3);
          setIsLoadingInfoData3(false);
        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[apiUrl]);
  

  if (
    isLoadingBannerData || isLoadingBannerBreadcrumb || isLoadingInfoData1 || isLoadingInfoData2 || isLoadingInfoData3
  ) {
    return (
      <div className="loading-animation">
        <span className="loader"></span>
      </div>
    );
  }

  return (
    <>
    <MetaData 
        title={title}
        description={description}
        keywords={keywords}
      />
      <SiteHeader />
      <SubpageBanner {...bannerData} {...bannerBreadcrumb}/>
      <section className="sections-common-sec steelFibreSec">
      <Info {...infoData1}/>
      <Info {...infoData2}/>
      <Info {...infoData3}/>
      </section>
      <Footer />
    </>
  );
};

export default SteelFibres;
