import React, { useState, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import { ApiLogistics } from "../API/API";
import "mapbox-gl/dist/mapbox-gl.css";

// mapboxgl.accessToken =
//   "pk.eyJ1IjoibWF5dWtoYiIsImEiOiJjbHVqaWo3ZWowYjRyMnJsYzJ1enMzNWJtIn0.Q4DzBzWrhg_QT8ExxrwrLA";

  mapboxgl.accessToken =
  "pk.eyJ1Ijoiam9lZGFyZGlzIiwiYSI6ImNseG5uMXV4MzA1ZG4yaXB3cHpqbmZmOWYifQ.YzVK7-5z5q93c81vpevOoQ";  

  // document.addEventListener('DOMContentLoaded', (event) => {
  //   mapboxgl.accessToken = 'pk.eyJ1Ijoiam9lZGFyZGlzIiwiYSI6ImNseG5uMXV4MzA1ZG4yaXB3cHpqbmZmOWYifQ.YzVK7-5z5q93c81vpevOoQ';
  //   // const map = new mapboxgl.Map({
  //   //   container: 'map', // ID of the HTML element where the map will be rendered
  //   //   style: 'mapbox://styles/mapbox/streets-v11', // Map style
  //   //   center: [-74.5, 40], // Initial center [longitude, latitude]
  //   //   zoom: 9 // Initial zoom level
  //   // });
  // });

const Map = () => {
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    const initializeMap = ({ setMap, mapContainer }) => {
      const mapInstance = new mapboxgl.Map({
        container: mapContainer,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [-98.5795, 39.8283],
        zoom: 2.5,
      });

      mapInstance.addControl(
        new mapboxgl.ScaleControl({
          maxWidth: 80,
          unit: "metric",
        })
      );

      setMap(mapInstance);
    };

    if (!map) initializeMap({ setMap, mapContainer: "map" });

    return () => map && map.remove();
  }, [map]);

  useEffect(() => {
    ApiLogistics()
      .then((response) => {
        if (
          response.data.data &&
          response.data.data.attributes &&
          response.data.data.attributes.map
        ) {
          const data = response.data.data.attributes.map;
          const newMarkers = data.map((item) => {
            return {
              id: item.id,
              title: item.name,
              coordinates: item.latlong.split(",").map(parseFloat),
            };
          });
          setMarkers(newMarkers);
        } else {
          console.log("Invalid response format:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  useEffect(() => {
    if (map && markers.length > 0) {
      markers.forEach((marker) => {
        const el = document.createElement("div");
        el.className = "marker";

        new mapboxgl.Marker(el).setLngLat(marker.coordinates).addTo(map);

        let popup = null;

        const showPopup = () => {
          popup = new mapboxgl.Popup({
            closeButton: false,
            offset: 15,
            anchor: "left",
            className: "custom-popup",
          })
            .setLngLat(marker.coordinates)
            .setHTML(`<p>${marker.title}</p>`)
            .addTo(map);
        };

        const hidePopup = () => {
          if (popup) {
            popup.remove();
            popup = null;
          }
        };

        // Show location name on hover (desktop)
        el.addEventListener("mouseenter", showPopup, { passive: true });
        el.addEventListener("mouseleave", hidePopup, { passive: true });
        // Show location name on touch (mobile)
        el.addEventListener("touchstart", showPopup, { passive: true });

        // Zoom to marker location on click
        el.addEventListener("click", () => {
          map.flyTo({ center: marker.coordinates, zoom: 10 });
        });

        // Zoom to marker location on touch
        el.addEventListener("touchend", () => {
          map.flyTo({ center: marker.coordinates, zoom: 10 });
        });
      });
    }
  }, [map, markers]);

  return <div id="map" style={{ width: "100%", height: "100vh" }} />;
};

export default Map;
