import React from "react";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/custom-style.css";
import "../../assets/css/variable.css";
import parse from "html-react-parser";

const BlogDetailComp = ({newsDate, newsTitle, newsImg, newsPara, newsAlt}) => {
  return (
    <section className="blog-details-sec">
      <div className="container-fluid px-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="blog-header">
              <p>{newsDate}</p>
              <h3>
                {newsTitle}
              </h3>
            </div>
            <div className="blog-main-content">
              <div className="blog-img">
                <img src={newsImg} alt={newsAlt} />
              </div>
              <div className="blog-text subtext">
                {newsPara && parse(newsPara)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetailComp;
