import React, { useState, useEffect } from "react";
import logo from "../../assets/images/ami-logo.svg";
import searchIcon from "../../assets/images/search.svg";
import closeIcon from "../../assets/images/close.svg";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/custom-style.css";
import "../../assets/css/variable.css";
import { Navbar, Nav, Image, NavDropdown } from "react-bootstrap";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";

const SiteHeader = ({apiDataLoaded}) => {
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isFixed, setIsFixed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const images = document.querySelectorAll("img");
    const promises = Array.from(images).map(img => {
      if (!img.complete) {
        return new Promise((resolve) => {
          img.onload = resolve;
          img.onerror = resolve;
        });
      }
      return Promise.resolve();
    });

    Promise.all(promises).then(() => {
      setImagesLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (apiDataLoaded) {
      setDataLoaded(true);
    }
  }, [apiDataLoaded]);

   const handleDropdownItemClick = (sectionId) => {
    console.log(dataLoaded);
    setTimeout(() => {
      if (!dataLoaded || !imagesLoaded) return; // Wait for both API data and images to load

      const section = document.getElementById(sectionId);
      if (section) {
        const navbarHeight = document.querySelector(".navbar").offsetHeight;
        const offsetTop = section.getBoundingClientRect().top;
        const scrollPosition = window.scrollY + offsetTop - navbarHeight;
        window.scrollTo({ top: scrollPosition, behavior: "smooth" });
      }
    }, 500); // Adjust the timeout as needed
  };

  const handleNavbarToggle = () => {
    setNavbarExpanded(!navbarExpanded);
  };

  const handleSearchClick = () => {
    setSearchClicked(!searchClicked);
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search?searchTerm=${searchQuery}`);
      setSearchClicked(false);
    }
  };

  const handleNavClick = (e, path) => {
    if (location.pathname === path) {
      e.preventDefault(); // Prevent the default link behavior
      window.scrollTo(0, 0); // Scroll to the top
      setNavbarExpanded(!navbarExpanded);
      // window.location.reload(); // Refresh the page
    }
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <header className="header-section">
      <div
        className={`${isFixed ? "main-navsec fixed-top" : "main-navsec"}`}
        id="navbar_top"
      >
        <div className="container-fluid px-5">
          <div className="row">
            <div className="col-md-12">
              <Navbar expand="lg" variant="light" expanded={navbarExpanded}>
                <Navbar.Brand
                  as={Link}
                  to="/"
                  className={`logosec ${searchClicked ? "inactive" : ""}`}
                >
                  
                  <Image
                    src={logo}
                    alt="ArcelorMittal Logo"
                    title="ArcelorMittal Logo"
                  />
                </Navbar.Brand>
                <Navbar.Toggle
                  aria-controls="navbarSupportedContent"
                  onClick={handleNavbarToggle}
                  className={`${searchClicked ? "inactive" : ""}`}
                />
                <Navbar.Collapse
                  id="navbarSupportedContent"
                  className={`${searchClicked ? "inactive" : ""}`}
                >
                  <Nav className="mx-auto">
                    <Nav.Item>
                      <Nav.Link as={NavLink} to="/" className="nav-link"  onClick={(e) => handleNavClick(e, '/')}>
                        Home
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        as={NavLink}
                        to="/structural-shapes"
                        className="nav-link"
                        onClick={(e) => handleNavClick(e, '/structural-shapes')}
                      >
                        Structural Shapes
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        as={NavLink}
                        to="/bars-and-rods"
                        className="nav-link"
                        onClick={(e) => handleNavClick(e, '/bars-and-rods')}
                      >
                        Bars and Rods
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link as={NavLink} to="/flats" className="nav-link" onClick={(e) => handleNavClick(e, '/flats')}>
                        Flats
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        as={NavLink}
                        to="/logistics"
                        className="nav-link"
                        onClick={(e) => handleNavClick(e, '/logistics')}
                      >
                        Logistics
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        as={NavLink}
                        to="/wire-solutions"
                        className="nav-link"
                        onClick={(e) => handleNavClick(e, '/wire-solutions')}
                      >
                        Wire Solutions
                      </Nav.Link>
                    </Nav.Item>
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Contact Us"
                    >
                      <NavDropdown.Item
                        as={NavLink}
                        to="/structural-shapes#contact"
                        onClick={() => handleDropdownItemClick("contact")}
                      >
                        Structural Shapes
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        as={NavLink}
                        to="/bars-and-rods#contact"
                        onClick={() => handleDropdownItemClick("contact")}
                      >
                        Bars and Rods
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="/flats#contact"
                        onClick={() => handleDropdownItemClick("contact")}
                      >
                        Flats
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="/logistics#contact"
                        onClick={() => handleDropdownItemClick("contact")}
                      >
                        Logistics
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="/wire-solutions#contact"
                        onClick={() => handleDropdownItemClick("contact")}
                      >
                        Wire Solutions
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Navbar.Collapse>
                <div className={`search-form ${searchClicked ? "active" : ""}`}>
                  <form onSubmit={handleSubmit}>
                    <input
                      type="text"
                      placeholder="Search.."
                      name="search"
                      value={searchQuery}
                      onChange={handleInputChange}
                    />
                    <button type="submit">
                      <Image src={searchIcon} alt="Search" />
                    </button>
                  </form>
                </div>
                <div
                  className={`nav-btns-sec ${searchClicked ? "active" : ""}`}
                >
                  <div className="header-search" onClick={handleSearchClick}>
                    <Image
                      src={searchIcon}
                      alt="Search"
                      className="search-ic"
                    />
                    <Image src={closeIcon} alt="Search" className="close-ic" />
                  </div>
                </div>
              </Navbar>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default SiteHeader;
