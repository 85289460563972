import React, {useEffect, useState} from "react";
import SiteHeader from "../../Header/SiteHeader";
import SubpageBanner from "../../Banner/SubpageBanner";
import Footer from "../../Footer/Footer";
import Info from "../../Info/Info";
import SubpageGrid from "../../Grid/SubpgeGrid";
import { ApiFencingVine, ApiFilterWireSol, ApiFilterHome } from "../../API/API";
import MetaData from "../../MetadataComp/MetaData";


const FencingVineyardWire=()=>{
  const apiUrl = process.env.REACT_APP_API_URL;

  const [title] = useState("Fencing and Vineyard Wire - ArcelorMittal International North America");
  const [description] = useState("ArcelorMittal International North America is the Leading supplier in the agricultural business for over 60 years. WireSolutions offers top-quality fencing and vineyard wire, designed to meet the highest standards in durability and performance. Our products provide exceptional strength and longevity, making them ideal for agricultural and industrial applications.");
  const [keywords] = useState("Fencing WIre, Vineyard Wire, Leading supplier, Fencing Catalog, Estate Wire Catalog, Vineyard / Trellising Wire Catalog");

  const [isLoadingBannerData, setIsLoadingBannerData] = useState(true);
  const [bannerData, setBannerData] = useState([
    {
      id: null,
      title: null,
    },
  ]);

    //API fetch for info block (Fencing Vine block)
const [isLoadingInfoData, setIsLoadingInfoData] = useState(true);
const [infoData, setinfoData] = useState([
    {
      id: 0,
      infoTitle: null,
      infoImage: null,
      infoPara: null,
      infoAlt: null,
      // isButtonEnabled: false,
    },
  ]);

  useEffect(() => {
    ApiFencingVine(apiUrl)
      .then((response) => {
        if (
          response.data.data.attributes.fencing_details
        ) {
          const item = response.data.data.attributes.fencing_details;

          const newInfoData = {
            id: item.id,
            infoTitle: item.title,
            infoPara: item.description,
            infoImage: `${item.image.data.attributes.url}`,
            infoUrl: item.page_url,
            infoAlt: item.img_alt_txt
          };
          setinfoData(newInfoData);
          setIsLoadingInfoData(false);
          ApiFilterWireSol(item.page_url)
      .then((response) => {
        if (response.data.data.attributes.businessline_block_item.length>0) {
          const item = response.data.data.attributes.businessline_block_item[0];

          const newBannerData = {
            id: item.id,
            title: item.title_text,
          };

          setBannerData(newBannerData);
          setIsLoadingBannerData(false);
        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[apiUrl]);
  
  const [isLoadingBannerBreadcrumb, setIsLoadingBannerBreadcrumb] = useState(true);
  const [bannerBreadcrumb, setBannerBreadcrumb] = useState([
    {
      id: null,
      pageLink: null,
      linkTitle: null
    },
  ]);
  
  useEffect(() => {
    ApiFilterHome("/wire-solutions")
      .then((response) => {
        if (response.data.data.attributes.businessline_block_item.length>0) {
          const item = response.data.data.attributes.businessline_block_item[0] ;
  
          const newBannerBreadcrumb = {
            id: item.id,
            pageLink: item.url,
            linkTitle: item.title_text
          };
  
          setBannerBreadcrumb(newBannerBreadcrumb);
          setIsLoadingBannerBreadcrumb(false);
        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[]);

       //Api fetch for Horizontal SubGrid

  const [isLoadingGridData, setIsLoadingGridData] = useState(true);
  const [gridData, setGridData] = useState([
    {
      id: 0,
      para1: null,
      para2: null,
    },
  ]);

  useEffect(() => {
    ApiFencingVine(apiUrl)
      .then((response) => {
        if (response.data.data.attributes.horizontal_block_fencing) {
          const item = response.data.data.attributes.horizontal_block_fencing;

          const newGridData = {
            id: item.id,
            para1: item.left_description,
            para2: item.right_description,
          };
          setGridData(newGridData);
          setIsLoadingGridData(false);
        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[apiUrl]);
  

  if (
    isLoadingBannerData || isLoadingBannerBreadcrumb|| isLoadingInfoData || isLoadingGridData
  ) {
    return (
      <div className="loading-animation">
        <span className="loader"></span>
      </div>
    );
  }

    return(
        <>
        <MetaData 
        title={title}
        description={description}
        keywords={keywords}
      />
        <SiteHeader />
        <SubpageBanner {...bannerData} {...bannerBreadcrumb}/>
        <section className="sections-common-sec barsnrods fencing">
        <Info {...infoData}/>
        </section>
        <div className="fencingVineyard-cont">
        <SubpageGrid {...gridData}/>
        </div>
        <Footer />
        </>
    )
}

export default FencingVineyardWire;