import React, {useState, useEffect} from "react";
import SiteHeader from "../../Header/SiteHeader";
import SubpageBanner from "../../Banner/SubpageBanner";
import Footer from "../../Footer/Footer";
import ProjGalleryAccordianInfo from "../../Info/ProjGalleryAccordianInfo";
import { ApiProjGal, ApiFilterStrucShape, ApiFilterHome } from "../../API/API";
import MetaData from "../../MetadataComp/MetaData";

const ProjectGallery = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [title] = useState("Project Gallery - ArcelorMittal International North America");
  const [description] = useState("Featured Projects Utilizing ArcelorMittal Structural Steel such as 1900 Lawrence Avenue, BMO Tower, 425 Park Ave, Bay Adelaide North, CIBC Square, 160 Front Street West, 150 N Riverside, Paseo de la Reforma");
  const [keywords] = useState("Featured Projects Utilizing ArcelorMittal Structural Steel");

  const [isLoadingBannerData, setIsLoadingBannerData] = useState(true);
  const [bannerData, setBannerData] = useState([
    {
      id: null,
      title: null,
    },
  ]);
  useEffect(() => {
    ApiFilterStrucShape('/structural-shapes/project-gallery')
      .then((response) => {
        if (response.data.data.attributes.businessline_block_item.length>0) {
          const item = response.data.data.attributes.businessline_block_item[0];

          const newBannerData = {
            id: item.id,
            title: item.title_text,
          };

          setBannerData(newBannerData);
          setIsLoadingBannerData(false);
        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[]);
  
  
//API fetch for Main Heading API
const [heading , setHeading ] = useState(null);
useEffect(() => {
  ApiProjGal(apiUrl)
    .then((response) => {
      if (response.data.data.attributes) {
        const item = response.data.data.attributes;
        setHeading(item.main_title);
      } 
      // else {
      //   console.log(response.data);
      // }
    })
    .catch((error) => {
      console.error("Error fetching data: ", error);
    });
}, [apiUrl]);

const [isLoadingBannerBreadcrumb, setIsLoadingBannerBreadcrumb] = useState(true);
const [bannerBreadcrumb, setBannerBreadcrumb] = useState([
  {
    id: null,
    pageLink: null,
    linkTitle: null
  },
]);

useEffect(() => {
  ApiFilterHome("/structural-shapes")
    .then((response) => {
      if (response.data.data.attributes.businessline_block_item.length>0) {
        const item = response.data.data.attributes.businessline_block_item[0] ;

        const newBannerBreadcrumb = {
          id: item.id,
          pageLink: item.url,
          linkTitle: item.title_text
        };

        setBannerBreadcrumb(newBannerBreadcrumb);
        setIsLoadingBannerBreadcrumb(false);
      } 
      // else {
      //   console.log(response.data);
      // }
    })
    .catch((error) => {
      console.error("Error fetching data: ", error);
    });
},[]);

//API fetch for Gallery Accordians 
const [isLoadingGalItems, setIsLoadingGalItems] = useState(true);
const [galItems, setGalItems] = useState([
    {
      id: 0,
      header: null,
      description: null,
      imgAlt: null,
      images: []
    },
  ]);

  useEffect(() => {
    ApiProjGal(apiUrl)
      .then((response) => {
        if (response.data.data.attributes.projectgallery_details) {
          const data = response.data.data.attributes.projectgallery_details;
          const newGalItems = data.map((item) => {
            const images = item.image.data.map(
              (imageData) => `${imageData.attributes.url}`
            );
            return {
              id: item.id,
              header: item.title,
              description: item.Description,
              imgAlt: item.img_alt_txt,
              images:images
            };
          });
          setIsLoadingGalItems(false);
          setGalItems(newGalItems);
        } 
        // else {
        //   console.log(response.data);
        // }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  },[apiUrl]);
 
  if (
    isLoadingBannerData ||
    isLoadingBannerBreadcrumb ||
    isLoadingGalItems
  ) {
    return (
      <div className="loading-animation">
        <span className="loader"></span>
      </div>
    );
  }
  return (
    <>
    <MetaData 
        title={title}
        description={description}
        keywords={keywords}
      />
      <SiteHeader />
      <SubpageBanner {...bannerData} {...bannerBreadcrumb}/>
      <ProjGalleryAccordianInfo heading={heading} galItems={galItems} />
      <Footer />
    </>
  );
};

export default ProjectGallery;
