import React, { useState } from "react";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
const Info = ({
  infoTitle,
  infoImage,
  infoPara,
  infoAlt,
  isButtonEnabled,
  isDownloadButtonEnabled,
  downloadButtonLabel,
  isReadEnabled,
  buttonRoute,
  isDropdownEnabled,
  dropdownLabel,
  files,
  fileLinks,
}) => {
  const [isReadMore, setIsReadMore] = useState(true);

  // Toggle read more / show less
  const handleReadMoreToggle = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <div className="info">
      <div className="container-fluid px-5">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="main-info">
              <div className="info-text">
                <h3>{infoTitle}</h3>
                {/* <div className="subtext">
                 {infoPara && parse(infoPara)} 
                 {isReadEnabled && (
                  <a className="txtbtn" >
                    Read More
                  </a>
                )}
                </div> */}
                <div
                  className={`subtext ${
                    isReadMore && isReadEnabled ? "expanded" : ""
                  }`}
                >
                  {infoPara && parse(infoPara)}
                </div>
                {isReadEnabled && (
                  <button className="txtbtn rd-btn" onClick={handleReadMoreToggle}>
                    {isReadMore ? "Read More" : "Show Less"}
                  </button>
                )}
              </div>
              {isButtonEnabled && (
                <Link
                  className="primary-link primary-link--dark"
                  to={buttonRoute}
                >
                  Read More
                  <svg
                    viewBox="0 0 33 14"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-labelledby="Long Arrow"
                    role="presentation"
                    className="svg-icon"
                  >
                    <title id="Long Arrow" lang="en">
                      Long Arrow icon
                    </title>
                    <polygon
                      points="25.1604773 0 32.6352213 6.85610681 25.1993812 13.7359725 24.3690601 12.8391022 30.1441884 7.49516092 0.0024154589 7.43569437 0 6.21588761 30.2001884 6.27516092 24.3378796 0.897439017"
                      stroke="none"
                      strokeWidth="1"
                      fill="currentColor"
                      fillRule="evenodd"
                    ></polygon>
                  </svg>
                </Link>
              )}
              {isDownloadButtonEnabled &&
                fileLinks.map((link, index) => (
                  <a
                    key={index}
                    className="primary-link primary-link--dark"
                    href={link.url}
                    target="_blank"
                    rel="noreferrer"
                    download
                  >
                    {link.title}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="svg-icon"
                    >
                      <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4" />
                      <polyline points="7 10 12 15 17 10" />
                      <line x1="12" y1="15" x2="12" y2="3" />
                    </svg>
                  </a>
                ))}
              {isDropdownEnabled && (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="primary"
                    id="dropdownMenuButton"
                    className="primary-link primary-link--dark d-flex align-items-center"
                  >
                    <span className="mr-2">{dropdownLabel}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="svg-icon"
                    >
                      <polyline points="7 10 12 15 17 10" />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {files.map((file, index) => (
                      <Dropdown.Item
                        key={index}
                        href={file.path}
                        target="_blank"
                        rel="noreferrer"
                        download
                      >
                        {file.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </div>
          {infoImage && (
            <div className="col-md-6">
              <div className="main-img-plot">
                <img src={infoImage} alt={infoAlt} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Info;
