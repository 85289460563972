import React, { useEffect, useState } from "react";
import SiteHeader from "../../Header/SiteHeader";
import SubpageBanner from "../../Banner/SubpageBanner";
import Footer from "../../Footer/Footer";
import BlogDetailComp from "../../LatestNews/BlogDetailComp";
import {ApiNewsDetail}  from "../../API/API";
import { useParams } from "react-router-dom";
import moment from 'moment-timezone';
import MetaData from "../../MetadataComp/MetaData";

const BlogDetail=()=>{
    const apiUrl = process.env.REACT_APP_API_URL;

  const [title] = useState("News Detail - ArcelorMittal International North America");
  const [description] = useState("Learn about the news details published on ArcelorMittal International North America");
  const [keywords] = useState("News, News Details, News of ArcelorMittal International North America");

    const formatDate = (dateString) => {

      const timeZone = 'Europe/Paris'; // CET timezone

      const date = moment.tz(dateString, timeZone);
    
      const day = date.format('DD');
      const month = date.format('MMMM'); // Full month name
      const year = date.format('YYYY');
      //const hours = date.format('HH');
      //const minutes = date.format('mm');
    
      //return `${day} ${month} ${year} ${hours}:${minutes}`;
      return `${month} ${day}, ${year}`;
    };
    
    const {id} = useParams();
    const [isLoadingBlogDetails, setIsLoadingBlogDetails] = useState(true);
    const [blogDetails, setBlogDetails] = useState([
        {
          id: null,
          newsDate: null,
          newsTitle: null,
          newsPara: null,
          newsImg: null,
          newsAlt: null,
        },
      ]);
    
      useEffect(() => {
        ApiNewsDetail(id,apiUrl)
          .then((response) => {
            if (
              response.data.data
            ) {
              const item = response.data.data[0];
              const newBlogDetails =  {
                
                  id: item.id,
                  newsImg: `${item.attributes.news_main_image.data.attributes.url}`,
                  newsDate: formatDate(item.attributes.publishedAt),
                  newsTitle: item.attributes.news_title,
                  newsPara: item.attributes.news_description,
                  newsAlt:  item.attributes.news_title               
              };
    
              setBlogDetails(newBlogDetails);
              setIsLoadingBlogDetails(false);
            } 
          })
          .catch((error) => {
            console.error("Error fetching data: ", error);
          });
      }, [id,apiUrl]);
    
    //api call
    const [isLoadingBannerData, setIsLoadingBannerData] = useState(true);
    const [bannerData, setBannerData] = useState([
        {
          id: null,
          title: null,
        },
      ]);
    
      useEffect(() => {
        ApiNewsDetail(id)
          .then((response) => {
            if (
              response.data.data
            ) {
              const item = response.data.data[0];
              const newBannerData =  {
                
                  id: item.id,
                  title: item.attributes.news_title,
                  pageLink: '/latest-news',
                  linkTitle: 'Our News'                
              };
    
              setBannerData(newBannerData);
              setIsLoadingBannerData(false);
            } 
          })
          .catch((error) => {
            console.error("Error fetching data: ", error);
          });
      }, [id]);

      if (isLoadingBannerData || isLoadingBlogDetails) {
        return (
          <div className="loading-animation">
           <span className="loader"></span>
          </div>
        );
      }

    return(
        <>
        <MetaData 
        title={title}
        description={description}
        keywords={keywords}
      />
        <SiteHeader />
        <SubpageBanner {...bannerData}/>
        <BlogDetailComp {...blogDetails} />
        <Footer />
        </>
    );
}

export default BlogDetail;