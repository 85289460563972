// import { hydrate, render } from "react-dom";
// import App from "./App";
// import { BrowserRouter } from "react-router-dom";

// const rootElement = document.getElementById("root");

// if (rootElement && rootElement.hasChildNodes()) {
//   hydrate(
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>,
//     rootElement
//   );
// } else {
//   render(
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>,
//     rootElement
//   );
// }

// import { hydrate, render } from "react-dom";
// import App from "./App";
// import { BrowserRouter } from "react-router-dom";

// const rootElement = document.getElementById("root");

// if (rootElement.hasChildNodes()) {
//   hydrate(
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>,
//     rootElement
//   );
// } else {
//   render(
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>,
//     rootElement
//   );
// }
import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  // If the root already has content (likely from a server-rendered page), hydrate it.
  hydrateRoot(
    rootElement,
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
} else {
  // Otherwise, just render the app normally.
  createRoot(rootElement).render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
