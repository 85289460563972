// import React from 'react';
// import { Helmet } from 'react-helmet';

// const MetaData = ({ title, description, keywords }) => {
//   return (
//     <Helmet>
//       <title>{title}</title>
//       <meta name="description" content={description} />
//       <meta name="keywords" content={keywords} />
//       <meta property="og:title" content={title} />
//       <meta property="og:description" content={description} />
//     </Helmet>
//   );
// };

// export default MetaData;
import React from 'react';
import { Helmet } from 'react-helmet';

const MetaData = ({ title = '', description = '', keywords = '' }) => {
  const trimmedTitle = title?.trim();
  const trimmedDescription = description?.trim();
  const trimmedKeywords = keywords?.trim();

  return (
    <Helmet>
      <title>{trimmedTitle}</title>
      <meta name="description" content={trimmedDescription} />
      <meta name="keywords" content={trimmedKeywords} />
      <meta property="og:title" content={trimmedTitle} />
      <meta property="og:description" content={trimmedDescription} />
    </Helmet>
  );
};

export default MetaData;
