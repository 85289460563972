import React, { useState, useEffect, useCallback } from "react";
import { Image } from "react-bootstrap";
import home from "../../assets/images/home-ic.svg";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/custom-style.css";
import "../../assets/css/variable.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ApiBlockSearch, ApiNewsSearch } from "../API/API";
import { debounce } from "lodash";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SearchPageComp = () => {
  const query = useQuery();
  const initialSearchQuery = query.get("searchTerm") || "";
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
  const [results, setResults] = useState([]);
  const [totalSearch, setTotalSearch] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState("desc");
  const [visibleResults, setVisibleResults] = useState(4);  //nos of item to be visible initially
  const navigate = useNavigate();
  const toLoad=4;  //nos of item to be load
  
  const newsDtlUrl = process.env.REACT_APP_NEWSD_URL;
  

  useEffect(() => {
    setSearchQuery(initialSearchQuery);
  }, [initialSearchQuery]);

  const fetchResults = async (query) => {
    if (!query.trim()) {
      setResults([]);
      setTotalSearch(0);
      setIsLoading(false);
      return;
    }
    setIsLoading(true); 
    try {
      const [newsResponse, blockResponse] = await Promise.all([
        ApiNewsSearch(query),
        ApiBlockSearch(query),
      ]);

      const newsData = newsResponse.data.data.map((item) => ({
        id: item.id,
        title: item.attributes.news_title,
        link: `${newsDtlUrl}${item.id}`,
      }));

      const blockData = blockResponse.data.data.map((item) => ({
        id: item.id,
        title: item.attributes.title,
        link: item.attributes.url.startsWith("/")
          ? item.attributes.url
          : `/${item.attributes.url}`,
      }));

      const combinedResults = [...newsData, ...blockData];
      setResults(combinedResults);
      setIsLoading(false);
      setTotalSearch(combinedResults.length);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedFetchResults = useCallback(debounce(fetchResults, 1000), []);

  // useEffect(() => {
  //   if (searchQuery) {
  //     debouncedFetchResults(searchQuery);
  //   }
  // }, [searchQuery, debouncedFetchResults]);

  useEffect(() => {
    if (searchQuery.trim()) {
      debouncedFetchResults(searchQuery);
    }
    else {
      setResults([]);
      setTotalSearch(0);
    }
  }, [searchQuery, debouncedFetchResults]);

  // Avoid setting state that triggers useEffect inside useEffect
  useEffect(() => {
    // Update URL only when necessary, without triggering the search query effect
    const query = new URLSearchParams(window.location.search);
    if (searchQuery.trim()) {
      query.set("searchTerm", searchQuery);
      navigate(`?${query.toString()}`);
    } else {
      query.delete("searchTerm");
      navigate(`?${query.toString()}`);
    }
  }, [searchQuery, navigate]);

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };


  const handleSortClick = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    // Sort the results locally without changing the URL
    const sortedResults = [...results].sort((a, b) => {
      if (newSortOrder === "asc") {
        return a.title.localeCompare(b.title);
      } else {
        return b.title.localeCompare(a.title);
      }
    });
    setResults(sortedResults);
  };

  const getSortLabel = () => {
    if (sortOrder === "asc") {
      return "Z-A";
    } else {
      return "A-Z";
    }
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      fetchResults(searchQuery);
      navigate(`/search?searchTerm=${searchQuery}`);
    } else if (!searchQuery) {
      navigate(`/search?searchTerm`);
    }
  };
  //load More function
  const loadMoreResults = () => {
    setVisibleResults((prevVisibleResults) => prevVisibleResults + toLoad);
  };

  if (
    isLoading
   ) {
     return (
       <div className="loading-animation1">
         <span className="loader"></span>
       </div>
     );
   }
  

  return (
    <>
      <section className="brdcrm-banner-section search-brd-crm">
        <div className="bread-crmsec">
          <div className="container-fluid px-5">
            <div className="main-brdcrmarea">
              <ul>
                <li>
                  <Link to="/" className="hm-link">
                    <Image src={home} alt="Home" />
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hm-link">
                    Search Result
                  </Link>
                </li>
              </ul>
              <h2>{totalSearch} {totalSearch <= 1 ? "Result" : "Results"} Found</h2>
            </div>
            <div className="search-boxsec">
              <form onSubmit={handleSubmit1}>
                <input
                  type="text"
                  id="gsearch1"
                  name="gsearch1"
                  placeholder="Search Here"
                  value={searchQuery}
                  onChange={handleInputChange}
                />

                <button type="submit" className="caret1"></button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="search-resultsec">
        <div className="container-fluid px-5">
          <div className="sort-row">
            <div className="row">
              <div className="col-md-12">
                <div className="sortsec">
                {totalSearch < 1 ? (<div className="alert alert-danger"><strong>*No result found, please try again</strong></div>) : totalSearch>=2 ? (
                  <span onClick={handleSortClick}>{getSortLabel()}</span>
                ) : null}
                </div>
                <div className="main-results">
                  {results.slice(0, visibleResults).map((result, index) => (
                    <div className="single-result" key={index}>
                      <p className="search-title">{result.title}</p>
                      <Link to={result.link}>Read More</Link>
                    </div>
                  ))}
                </div>
                {visibleResults < totalSearch && (
                  <div className="view-more-btnsec text-start">
                    <button className="primary-link primary-link--dark" onClick={loadMoreResults}>
                      Load More
                      <svg
                        viewBox="0 0 33 14"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-labelledby="Long Arrow"
                        role="presentation"
                        className="svg-icon"
                      >
                        <title id="Long Arrow" lang="en">
                          Long Arrow icon
                        </title>
                        <polygon
                          points="25.1604773 0 32.6352213 6.85610681 25.1993812 13.7359725 24.3690601 12.8391022 30.1441884 7.49516092 0.0024154589 7.43569437 0 6.21588761 30.2001884 6.27516092 24.3378796 0.897439017"
                          stroke="none"
                          strokeWidth="1"
                          fill="currentColor"
                          fillRule="evenodd"
                        ></polygon>
                      </svg>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SearchPageComp;
