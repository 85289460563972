import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";

const GallCarousel = ({ images, imgAlt }) => {
  const [activeDotIndex, setActiveDotIndex] = useState(0);
  const sliderRef = useRef(null);
  const dotsRef = useRef(null); // Ref for dots container

  const settings = {
    infinite: true,
    draggable: false,
    autoplay: true,
    autoplaySpeed: 4700,
    speed: 500,
    arrows: false,
    pauseOnHover: false,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setActiveDotIndex(next),
  };

  useEffect(() => {
    const slider = sliderRef.current;
    const dots = dotsRef.current;
    if (slider && slider.innerSlider && dots) {
      setActiveDotIndex(slider.innerSlider.state.currentSlide);
      updateCircle(activeDotIndex, dots); // Update circle initially
    }
  }, [activeDotIndex, images.length]);

  const handleDotClick = (index) => {
    setActiveDotIndex(index);
    const slideIndex = index * settings.slidesToScroll;
    sliderRef.current.slickGoTo(slideIndex);
    updateCircle(index, dotsRef.current);
  };

  const updateCircle = (activeIndex, dotsContainer) => {
    const dots = dotsContainer.querySelectorAll('.slick-dot button');
    dots.forEach((dot, index) => {
      const circle = dot.querySelector('.progress-svg');
      if (circle) {
        dot.removeChild(circle);
      }
      if (index === activeIndex) {
        const newCircle = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        newCircle.setAttribute('class', 'progress-svg');
        newCircle.innerHTML = '<circle class="circle-bg" r="9" cx="10" cy="10"></circle><circle class="circle-go" r="9" cx="10" cy="10"></circle>';
        dot.appendChild(newCircle);
      }
    });
  };

  const renderCustomDots = () => {
    const dots = [];
    for (let i = 0; i < images.length; i++) {
      dots.push(
        <li
          key={i}
          className={`slick-dot ${activeDotIndex === i ? 'slick-active' : ''}`}
          onClick={() => handleDotClick(i)}
        ><button></button></li>
      );
    }
    return dots;
  };

  const [shouldDisable, setShouldDisable] = useState(false);
  useEffect(() => {
    setShouldDisable(images.length <= 1);
  }, [images.length]);

  const handlePrevClick = () => {
    if (sliderRef.current && activeDotIndex > 0) {
      sliderRef.current.slickPrev();
      const newIndex = activeDotIndex - 1;
      setActiveDotIndex(newIndex);
      updateCircle(newIndex, dotsRef.current);
    }
  };

  const handleNextClick = () => {
    if (sliderRef.current && activeDotIndex < images.length - 1) {
      sliderRef.current.slickNext();
      const newIndex = activeDotIndex + 1;
      setActiveDotIndex(newIndex);
      updateCircle(newIndex, dotsRef.current);
    }
  };

  return (
    <div className="main-img-plot">
      {images.length > 1 ? (
        <Slider {...settings} ref={sliderRef}>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={imgAlt}/>
            </div>
          ))}
        </Slider>
      ) : (
        <>
        <img src={images[0]} alt={imgAlt} />
        </> 
      )}
      {!shouldDisable && (
        <div className="custom-slick-nav slnav1">
          <button className="slick-prev" onClick={handlePrevClick}>Previous</button>
          <ul className="slick-dots" ref={dotsRef}>
            {renderCustomDots()}
          </ul>
          <button className="slick-next" onClick={handleNextClick}>Next</button>
        </div>
      )}
    </div>
  );
};

export default GallCarousel;
