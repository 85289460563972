import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;



//Landing Page's API

export const ApiHome = () => {
  return axios.get(
    `${apiUrl}/api/page-home?populate[1]=banner_section.main_img&populate[2]=businessline_block_item.bg_img&populate[3]=businessline_block_item.link&populate[4]=newsletter_section&populate[5]=seo`
  );
};

export const ApiStrucShape = () => {
  return axios.get(
    `${apiUrl}/api/page-structural-shape?populate[1]=banner_section.main_img&populate[2]=businessline_block_item.bg_img&populate[3]=businessline_block_item.link&populate[4]=technical_assessment.technical_assessment_img&populate[5]=Contact_us1&populate[6]=contact_us1_fields.logo&populate[7]=Contact_us2&populate[8]=contact_us2_fields.logo&populate[9]=seo`
  );
};

export const ApiBarsRods = () => {
  return axios.get(
    `${apiUrl}/api/page-bars-rod?populate[1]=banner_section.main_img&populate[2]=businessline_block_item.bg_img&populate[3]=businessline_block_item.link&populate[4]=businessline_block_item.image_carousel&populate[5]=Contact_us1&populate[6]=contact_us1_fields.logo&populate[7]=seo`
  );
};

export const ApiLogistics = () => {
  return axios.get(
    `${apiUrl}/api/page-logistic?populate[1]=banner_section.main_img&populate[2]=businessline_block_item.bg_img&populate[3]=businessline_block_item.link&populate[4]=Contact_us1&populate[5]=contact_us1_fields.logo&populate[6]=map&populate[7]=logistics_side_by_side_contents&populate[8]=seo`
  );
};

export const ApiFlats = () => {
  return axios.get(
    `${apiUrl}/api/page-flat?populate[1]=banner_section.main_img&populate[2]=businessline_block_item.bg_img&populate[3]=businessline_block_item.link&populate[4]=Contact_us1&populate[5]=contact_us1_fields.logo&populate[6]=Contact_us2&populate[7]=contact_us2_fields.logo&populate[8]=sustainaibility.side_img&populate[9]=seo`
  );
};

export const ApiWireSol = () => {
  return axios.get(
    `${apiUrl}/api/page-wire-solution?populate[1]=banner_section.main_img&populate[2]=businessline_block_item.bg_img&populate[3]=businessline_block_item.link&populate[4]=Contact_us1&populate[5]=contact_us1_fields.logo&populate[6]=Contact_us2&populate[7]=contact_us2_fields.logo&populate[8]=pdf_upload.pdf&populate[9]=seo`
  );
};

//FLATS Subpage's API

export const ApiAgri = () => {
  return axios.get(
    `${apiUrl}/api/subpage-flats-agriculture?populate[1]=agriculture_details.image`
  );
};

export const ApiAppliance = () => {
  return axios.get(
    `${apiUrl}/api/subpage-flats-appliance?populate[1]=appliance_details.image`
  );
};

export const ApiAuto = () => {
  return axios.get(
    `${apiUrl}/api/subpage-flats-automotive?populate[1]=automotive_details.image`
  );
};

export const ApiBuilding = () => {
  return axios.get(
    `${apiUrl}/api/subpage-flats-building?populate[1]=building_details.image`
  );
};

export const ApiCAG = () => {
  return axios.get(
    `${apiUrl}/api/subpage-flats-construction?populate[1]=construction_details.image`
  );
};

export const ApiEnergy = () => {
  return axios.get(
    `${apiUrl}/api/subpage-flats-energy?populate[1]=energy_details.image`
  );
};

export const ApiPackaging = () => {
  return axios.get(
    `${apiUrl}/api/subpage-flats-packaging?populate[1]=packaging_details.image`
  );
};

export const ApiTrans = () => {
  return axios.get(
    `${apiUrl}/api/subpage-flats-transportation?populate[1]=transportation_details.image`
  );
};

export const ApiFlatsSustain = () => {
  return axios.get(
    `${apiUrl}/api/subpage-flats-sustainaibility?populate[1]=sustainaibility_details.image&populate[2]=xcarbs_details_1.image&populate[3]=xcarbs_details_1.pdf_items.pdf&populate[4]=xcarbs_details_2.image&populate[5]=xcarbs_details_2.pdf_items.pdf`
  );
};

export const ApiFilterFlat = (UrlFilterValue) =>{
  return axios.get(
    `${apiUrl}/api/page-flat?populate[businessline_block_item][filters][url][$eq]=${UrlFilterValue}`
  );
}

//WIRE SOLUTION Subpage's API

export const ApiWireRopes = () => {
  return axios.get(
    `${apiUrl}/api/subpage-wiresol-wirerope?populate[1]=section1_details.image&populate[2]=horizontal_content&populate[3]=wiresol_accord&populate[4]=section2_details`
  );
};

export const ApiFencingVine = () => {
  return axios.get(
    `${apiUrl}/api/subpage-wiresol-fencing?populate[1]=fencing_details.image&populate[2]=horizontal_block_fencing`
  );
};

export const ApiSteelFibres = () => {
  return axios.get(
    `${apiUrl}/api/subpage-wiresol-steel?populate[1]=section1_details.image&populate[2]=section2_details.image&populate[3]=section3_details.image`
  );
};

export const ApiFilterWireSol = (UrlFilterValue) =>{
  return axios.get(
    `${apiUrl}/api/page-wire-solution?populate[businessline_block_item][filters][url][$eq]=${UrlFilterValue}`
  );
}


//STRUCTURAL SHAPES Subpage's API

export const ApiProd_Size = () => {
  return axios.get(
    `${apiUrl}/api/subpage-structuralshape-product?populate[1]=section1_details.image&populate[2]=horizontal_block_product&populate[3]=pdf_item.pdf`
  );
};

export const ApiA913Steel = () => {
  return axios.get(
    `${apiUrl}/api/subpage-stshape-a913?populate[1]=sec1.image&populate[2]=process_acrd_dtls.image&populate[3]=tough_acrd_dtls.image&populate[4]=code_acrd_dtls.image&populate[5]=seismic_acrd_dtls.image&populate[6]=avl_acrd_dtls.image&populate[7]=weight_acrd_dtls.image&populate[8]=weld_acrd_dtls1.image&populate[9]=a913_table&populate[10]=weld_acrd_table1&populate[11]=weld_acrd_table2`
  );
};

export const ApiStrucShp_Sustain = () => {
  return axios.get(
    `${apiUrl}/api/subpage-structuralshape-sustainaibility?populate[1]=section1_details.image&populate[2]=section2_details.image&populate[3]=section2_details.pdf_items.pdf.pdf.pdf&populate[4]=section3_details.image&populate[5]=section3_details.pdf_items.pdf.pdf.pdf&populate[6]=section4_details.image&populate[7]=section4_details.pdf_items.pdf.pdf.pdf&populate[8]=section5_details.image`
  );
};

export const ApiProjGal = () => {
  return axios.get(
    `${apiUrl}/api/subpage-structuralshape-project?populate[1]=projectgallery_details.image`
  );
};

export const ApiFilterStrucShape = (UrlFilterValue) =>{
  return axios.get(
    `${apiUrl}/api/page-structural-shape?populate[businessline_block_item][filters][url][$eq]=${UrlFilterValue}`
  );
}

//Latest New's API
export const ApiLatestNews = () => {
  return axios.get(
    `${apiUrl}/api/news-items?populate=*`
  );
};

export const ApiNewsDetail = (id) => {
  return axios.get(
    `${apiUrl}/api/news-items?populate[1]=news_thumbnail&populate[2]=news_main_image&[filters][id][$eq]=${id}`
  );
};

export const ApiHomeLatestNews = () => {
  return axios.get(
    `${apiUrl}/api/page-home?populate[1]=latest_blogs_title&populate[2]=number_of_blog_item_to_show`
  );
};

export const ApiFilterHome = (UrlFilterValue) =>{
  return axios.get(
    `${apiUrl}/api/page-home?populate[businessline_block_item][filters][url][$eq]=${UrlFilterValue}`
  );
}

//PR_Table's API
export const ApiCategory = () => {
  return axios.get(
    `${apiUrl}/api/ss-master-categories?populate=*`
  );
};

export const ApiSubcategory = () => {
  return axios.get(
    `${apiUrl}/api/ss-master-subcategories?populate=*`
  );
};

export const ApiTableValue = () => {
  return axios.get(
    `${apiUrl}/api/ss-table-values?pagination[limit]=100000000&populate=*`
  );
}
// `${apiUrl}/api/ss-table-values?sort[0]=section:desc&pagination[limit]=100000000&populate=*`
//A913_Table's API

export const ApiMechTable= () => {
  return axios.get(
    `${apiUrl}/api/a913-mecal-tbls?populate=*`
  );
}

export const ApiWeldTable1= () => {
  return axios.get(
    `${apiUrl}/api/a913-weldb-tbl-1s?populate=*`
  );
}

export const ApiWeldTable2= () => {
  return axios.get(
    `${apiUrl}/api/a913-weldb-tbl-2s?populate=*`
  );
}

//Search Function's API
export const ApiBlockSearch = (searchKey) => {
  return axios.get(
    `${apiUrl}/api/search-blocks?filters[desc][text][$contains]=${searchKey}`
  );
};

export const ApiNewsSearch = (searchKey) => {
  return axios.get(
    `${apiUrl}/api/news-items?filters[$or][0][news_title][$contains]=${searchKey}&filters[$or][1][news_description][$contains]=${searchKey}&populate=*`
  );
}

//Social Media Link Api
export const ApiSocialMedia = () =>{
  return axios.get(
    `${apiUrl}/api/social-media?populate=*`
  )
}